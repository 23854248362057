import { Box, Stack } from "@mui/material";
import { Link } from "react-router-dom";

import LogoURL from "../../assets/img/logo.gif";
import timeURL from "../../assets/img/interface/time.png";
import sustainableURL from "../../assets/img/interface/sustainable.png";
import lockURL from "../../assets/img/interface/lock.png";
import yieldURL from "../../assets/img/yield.png";
import eliteURL from "../../assets/img/elite.png";
import partnersURL from "../../assets/img/partners.png";
import "./home.scss";
import { Container } from "@mui/system";
import ContentFooter from "../../components/contentFooter";

import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";
import { useState } from "react";

const fadeInAnimation = keyframes`${fadeIn}`;

const FadeInDiv = styled.div`
  animation: 1s ${fadeInAnimation};
`;

export default function Home() {
  const [isCRO, setIsCRO] = useState(0);

  return (
    <Box className="home-container">
      <FadeInDiv className="first-section">
        <Container>
          <Stack direction="column" alignItems="center">
            <img src={LogoURL} alt="logo" />
            <h3 className="subtitle">SONIC FINANCE</h3>
            <p className="subdescription">
              CA: 0x19F233925DFA7aca402B93aA32F6083B9221a40c
            </p>
            <br />
            <p className="subdescription" style={{ fontSize: 20 }}>
              SONIC FINANCE is a decentralized finance (DeFi) platform operating
              on the Ethereum Network, providing users with access to
              single-asset staking vaults, primarily recommending the use of
              Meme coins, as well as a variety of other smart contract-based
              applications, allowing regular users to easily engage with DeFi
              protocols.
            </p>
          </Stack>
        </Container>
      </FadeInDiv>

      <FadeInDiv className="special-section">
        <Container>
          <Stack
            direction={{ lg: "row", md: "row", sm: "column", xs: "column" }}
            justifyContent="space-between"
            spacing={4}
          >
            <div className="card1">
              <h2 style={{ margintTop: 80 }}>
                Stake in the Vaults
                <br />
                <span >Compound your Gains</span>
              </h2>
              <p>
                SONIC FINANCE has developed and currently manages a collection
                of 7 distinctive vault contracts functioning as single-asset
                staking pools with a medium to long-term focus on return on
                investment. These staking pools are ideal for holding assets in
                a savings-oriented manner, and they provide an extra means of
                value distribution to the supported ecosystems, their tokens,
                and their holders.
                <br />
                <br />
                <a
                  className="btn btn-md btn-light btn-outline-success text-white roundedCorners"
                  href="/vaults"
                >
                  View Vaults
                </a>
              </p>
            </div>
            <div>
              <img src={yieldURL} width="100%" />
            </div>
          </Stack>
        </Container>
      </FadeInDiv>

      <FadeInDiv className="special-section">
        <Container>
          <Stack
            direction={{ lg: "row", md: "row", sm: "column", xs: "column" }}
            justifyContent="space-between"
            spacing={4}
          >
            <div className="image-order">
              <img src={eliteURL} width="100%" />
            </div>
            <div className="card1">
              <h2 style={{ margintTop: 80, color: "white" }}>
                SONIC FINANCE Technology.
                <br />
                <span >Elite Tokenomics.</span>
              </h2>
              <p>
                At the heart of SONIC FINANCE's SOFI token lies the ERC-20
                standard, providing robust liquidity management and control
                capabilities for the entire ecosystem. Through the use of
                deflationary tokenomics and reusable locked liquidity, SOFI is a
                token that will appreciate in both value and utility as the
                ecosystem grows and evolves.
                <br />
                <br />
                <a className="btn btn-md btn-light btn-outline-success text-white roundedCorners btn-disabled">
                  Stake SOFI (development)
                </a>
              </p>
            </div>
          </Stack>
        </Container>
      </FadeInDiv>

      <FadeInDiv className="special-section">
        <Container>
          <Stack
            direction={{ lg: "row", md: "row", sm: "column", xs: "column" }}
            justifyContent="space-between"
            spacing={4}
          >
            <div className="card1">
              <h2 style={{ margintTop: 80, color: "white" }}>
                Powerful Partnerships.
                <br />
                <span >Solid Foundation.</span>
              </h2>
              <p>
                SONIC FINANCE will form partnerships with a robust network of
                projects, protocols, and their communities. SONIC FINANCE will
                be driving future growth and generating new utility, with the
                support and assistance of its partners, further strengthening
                its position in the market.
                <br />
                <br />
              </p>
            </div>
            <div>
              <img src={partnersURL} width="100%" />
            </div>
          </Stack>
        </Container>
      </FadeInDiv>

      <FadeInDiv className="special-section">
        <Container>
          <h2 className="subtitle">SOFI Buy/Sell Taxes</h2>
          <h3 className="subtitle">1%/1%</h3>
        </Container>
      </FadeInDiv>

      <FadeInDiv className="second-section">
        <Container>
          <h3 className="subtitle">Why SONIC FINANCE?</h3>
          <Stack
            direction={{ lg: "row", md: "row", sm: "column", xs: "column" }}
            justifyContent="space-between"
            spacing={4}
          >
            <Box className="card1 ">
              <img src={timeURL} alt="Time" />
              <h3 className="subtitle colouredText">Long-Term</h3>
              <p>
                SONIC FINANCE embraces DeFi including yield farming and
                generation technology, with permissionless contracts at its
                core.
                <br />
                <br />
                The protocol's future evolution will generate limitless
                possibilities for its users, incorporating platform-owned
                liquidity, time-locked positions, and both first-party and
                third-party development.
              </p>
            </Box>
            <Box className="card1 ">
              <img src={sustainableURL} alt="sustainable" />
              <h3 className="subtitle colouredText">Sustainable</h3>
              <p>
                Before deployment on the Mainnet, any modification to the core
                protocol undergoes rigorous testing and peer review.
                <br />
                <br />
                Continuous refinement and optimization are employed to reduce
                risk.
              </p>
            </Box>
            <Box className="card1 ">
              <img src={lockURL} alt="lock" />
              <h3 className="subtitle colouredText">Open-Source</h3>
              <p>
                SONIC FINANCE is an open platform - engagement necessitates only
                a compatible Web3-enabled wallet application or extension.
                <br />
                <br />
                Smart contracts are open-source and decentralised, making
                implementing protocol practically unstoppable.
              </p>
            </Box>
          </Stack>
        </Container>
      </FadeInDiv>
      <FadeInDiv className="third-section">
        <Container>
          <ContentFooter />
        </Container>
      </FadeInDiv>
    </Box>
  );
}
