import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";

import SavingsIcon from "@mui/icons-material/Savings";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import NetworkCheckIcon from "@mui/icons-material/NetworkCheck";
import MenuIcon from "@mui/icons-material/Menu";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Setting from "../settingButton";

import croUrl from "../../assets/img/cro.png";
import ethUrl from "../../assets/img/eth.png";
import bnbUrl from "../../assets/img/bnb.png";
import "./header.scss";

import ReceiveTokenModal from "../receiveTokenModal";
import VideoModal from "../videoModal";

// import { SliderBar_toggle, Mode_toggle } from "../../redux/actions";
import {
  wallet_connect,
  wallet_disconnect,
  VideoModal_toggle,
} from "../../redux/actions";

import styled, { keyframes } from "styled-components";
import { fadeInDown } from "react-animations";
import { Common } from "../../redux/common";
import { useMemo } from "react";

let mode;

const MaterialUISwitch = styled(Switch)(({ theme }) => {
  return {
    width: 62,
    height: 30,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      marginTop: 4,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(22px)",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            "#1D1F22"
          )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: mode === true ? "#1d1f22" : "#AAB4BE",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: mode !== true ? "#EEE" : "#1d1f22",
      width: 30,
      height: 30,
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: mode !== true ? "#8796A5" : "#aab4be",
      borderRadius: 20 / 2,
    },
  };
});

const fadeInDownAnimation = keyframes`${fadeInDown}`;

const FadeInDownDiv = styled.div`
  animation: 1s ${fadeInDownAnimation};
`;

export default function Header() {
  const [isShowReceiveModal, setIsShowReceiveModal] = useState(false);
  const dispatch = useDispatch();
  const [menuShow, setMenuShow] = useState(false);
  // { mode } = Common();
  const { account, balance, currencySymbol, videoToggle } = Common();
  const tokenUrl = useMemo(()=>{
    if (currencySymbol == "ETH") return ethUrl;
    else if (currencySymbol == "BNB") return bnbUrl;
    else if (currencySymbol == "CRO") return croUrl;
    else return null;
  }, currencySymbol)

  useEffect(() => {
    connectWallet();
    if (window.ethereum) {
      window.ethereum.on("chainChanged", (chainId) => {
        connectWallet();
      })
    }
  }, []);

  const connectWallet = () => {
    dispatch(wallet_connect())
  };
  const disconnectWallet = () => {
    dispatch(wallet_disconnect());
  };
  // const sliderToggle = () => {
  //   dispatch(SliderBar_toggle());
  // };

  // const setDisplayMode = (v) => {
  //   dispatch(Mode_toggle(v));
  // };

  const videoModalToggle = () => {
    dispatch(VideoModal_toggle(false));
  };

  const openReceiveTokenModalHandle = (e) => {
    if (!e.target.closest(".dropdown-list")) setIsShowReceiveModal(!isShowReceiveModal);
  };

  return (
    <Box className="header-wrap">
      <Box>
        <Link className="logo-text" to="/home">
          <span>SONIC FINANCE</span>
        </Link>
      </Box>
      <Box className="desktop">
        <Link className="item desktop" to="/dashboard">
          <span>
            <NetworkCheckIcon sx={{ fontSize: "20px" }} />
          </span>
          <label>DASHBOARD</label>
        </Link>
        {/* <Link className="item desktop" to="/stake/SOFI">
          <span>
            <SavingsIcon sx={{ fontSize: "20px" }} />
          </span>
          <label>Elite Staking</label>
        </Link> */}
        {/* <Link className="item desktop" to="/armory">
          <span>
            <LocalFireDepartmentIcon sx={{ fontSize: "20px" }} />
          </span>
          <label>Armory</label>
        </Link> */}
        {/* <Link className="item desktop" to="/swap">
          <span>
            <AutorenewIcon sx={{ fontSize: "20px" }} />
          </span>
          <label>AMMO Swap</label>
        </Link> */}
        <Link className="item desktop" to="/vaults">
          <span>
            <AttachMoneyIcon sx={{ fontSize: "20px" }} />
          </span>
          <label>Vaults Staking</label>
        </Link>
      </Box>
      <Box className="desktop">
        <Box className="receive mr-5">
          <button
            className="btn hover-effect-ignore"
            // onClick={openReceiveTokenModalHandle}
          >
            {tokenUrl && <img src={tokenUrl} alt="alarm" />}
            {
              account && (
                <span>{Number(balance).toFixed(6)}{" "}{currencySymbol?currencySymbol:""}</span>
              )
            }
          </button>
          {/* {account && (
            <Box className="dropdown-list">
              <button>
                <AttachMoneyIcon size="small" />
                <span>
                  {account ? account.slice(0, 4) + "..." + account.slice(-2) : ""}
                </span>
              </button>
              <button onClick={disconnectWallet}>
                <LogoutIcon size="small" />
                <span>DISCONNECT</span>
              </button>
            </Box>
          )} */}
        </Box>
        <Link className="btn" to="profile">
          <PersonIcon sx={{ fontSize: "20px" }} />
        </Link>
        <Setting />
        {/* <FormControlLabel
          control={
            <MaterialUISwitch
              onChange={(e) => setDisplayMode(e.target.value)}
              value="eee"
            />
          }
        /> */}
      </Box>

      <button
        className="btn mobile mobile-menu"
        onClick={() => setMenuShow(!menuShow)}
      >
        <MenuIcon sx={{ fontSize: "20px" }} />
      </button>
      {menuShow ? (
        <FadeInDownDiv className="mobile-content mobile">
          <Link className="item" to="/dashboard">
            <span>
              <NetworkCheckIcon sx={{ fontSize: "20px" }} />
            </span>
            <label>Dashboard</label>
          </Link>
          {/* <Link className="item" to="/stake/SOFI">
            <span>
              <SavingsIcon sx={{ fontSize: "20px" }} />
            </span>
            <label>Elite Staking</label>
          </Link> */}
          {/* <Link className="item" to="/armory">
            <span>
              <LocalFireDepartmentIcon sx={{ fontSize: "20px" }} />
            </span>
            <label>Armory</label>
          </Link> */}
          {/* <Link className="item" to="/swap">
            <span>
              <AutorenewIcon sx={{ fontSize: "20px" }} />
            </span>
            <label>AMMO Swap</label>
          </Link> */}
          <Link className="item" to="/vaults">
            <span>
              <AttachMoneyIcon sx={{ fontSize: "20px" }} />
            </span>
            <label>Vaults Staking</label>
          </Link>

          <Box className="mobile-tool-wrap">
            <Box>
              <button
                className="btn mr-5 receive"
                onClick={openReceiveTokenModalHandle}
              >
                {tokenUrl && <img src={tokenUrl} alt="alarm" />}
                <span>0</span>
              </button>
              <Link className="btn" to="profile">
                <PersonIcon sx={{ fontSize: "20px" }} />
              </Link>
              {/* <FormControlLabel
                control={
                  <MaterialUISwitch
                    onChange={(e) => setDisplayMode(e.target.value)}
                  />
                }
              /> */}
              <Setting />
            </Box>
          </Box>
        </FadeInDownDiv>
      ) : (
        ""
      )}
      <ReceiveTokenModal open={isShowReceiveModal} openToggleHandle={setIsShowReceiveModal} />
      <VideoModal open={videoToggle} openToggleHandle={videoModalToggle} />
    </Box>
  );
}
