import { useEffect } from "react";
import { Common } from "../../redux/common";

export default function Footer() {
  const { isShowFooter } = Common();
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://widgets.coingecko.com/coingecko-coin-price-marquee-widget.js";
    script.async = true;
    // script.onload = () => this.scriptLoaded();

    document.body.appendChild(script);
  }, []);

  return (
    <nav className="footer-wrap">
      {
        isShowFooter && 
        <coingecko-coin-price-marquee-widget
          coinIds="bitcoin,ethereum,dai,binance-usd,cardano,wbnb,sidekick-token"
          currency="usd"
          background-Color="#111417"
          locale="en"
          font-color="#ffffff"
        ></coingecko-coin-price-marquee-widget>
      }
    </nav>
  );
}
