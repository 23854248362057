import { toast } from "react-toastify";

export const shortId = function (str, size) {
  return String(str).substr(0, size) + '...' + String(str).substr(String(str).length - size, String(str).length);
}

export const convertEthToWei = function (e) {
  return 1e18 * e
}

export const convertWeiToEth = function (e) {
  return e / 1e18
}

export function copyToClipboard(text) {
  const textField = document.createElement('textarea')
  textField.innerText = text
  document.body.appendChild(textField)
  textField.select()
  document.execCommand('copy')
  textField.remove()
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function exactNumber(num, fixed) {
  var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
  return num.toString().match(re)[0];
}

export async function usernameOf(_addressToCheck, namesInstance) {
  console.log("User Address is " + _addressToCheck);
  let result = await namesInstance.getNameByAddress(_addressToCheck);
  console.log("User who just did that is " + result);
  if (result.length == 0) {
    return "Someone";
  }
  return result;
}
export async function transferTokens(receiver, amount, Token, nameToken, tokenName) {
  if (receiver === "") {
    toast.error("Please Input Recipient Address!", {
      position: "top-right",
      theme: "colored",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return;
  }
  console.log(receiver, amount, "ddddddd");

  try {
    await Token.transfer(String(receiver).trim(), String(convertEthToWei(String(amount).trim(0))));
    toast.success('Transferring ' + numberWithCommas(amount) + ` ${tokenName} to ` + usernameOf(receiver, nameToken), {
      position: "top-right",
      theme: "colored",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } catch (err) {
    console.dir(err);
    let errText = "";
    if (err.code === "INVALID_ARGUMENT") {
      errText = "Address Invalid!";
    } else if (err.code === "UNPREDICTABLE_GAS_LIMIT") {
      errText = "Insufficient Gas!";
    } else if (err.code === "NUMERIC_FAULT") {
      errText = "Insufficient Amount!";
    } else {
      errText = "Faild!";
    }
    toast.error(errText, {
      position: "top-right",
      theme: "colored",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}