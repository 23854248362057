import { useState } from "react";
import { Box, Grid, Stack } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import loaderURL from "../../assets/img/interface/loader.svg";
import recycleURL from "../../assets/img/icon/recycle.png";
import tractorURL from "../../assets/img/icon/tractor.png";
import icon1URL from "../../assets/img/icon/machine.png";
import icon2URL from "../../assets/img/icon/2.png";
import icon3URL from "../../assets/img/icon/1.png";
import icon4URL from "../../assets/img/icon/9.png";
import icon5URL from "../../assets/img/icon/3.png";
import icon6URL from "../../assets/img/icon/back.png";

import { Container } from "@mui/system";
import ContentFooter from "../../components/contentFooter";
import WrongNetwork from "../../components/wrongNetwork";

import "./vault.scss";

import styled, { keyframes } from "styled-components";
import { fadeIn, fadeInUp } from "react-animations";
import { useEffect } from "react";

import xskCoin from "../../assets/img/tokens/xsk.png";
import SonicCoin from "../../assets/img/tokens/sonic.png";
import xhnwCoin from "../../assets/img/tokens/xhnw.png";
import trunkCoin from "../../assets/img/tokens/trunk.png";
import busdCoin from "../../assets/img/tokens/BUSD.png";
import wbnbCoin from "../../assets/img/tokens/wbnb.png";
import cakeCoin from "../../assets/img/tokens/cake.png";
import daiCoin from "../../assets/img/tokens/dai.png";
import ethCoin from "../../assets/img/tokens/eth.png";

import adaCoin from "../../assets/img/tokens/ADA.png";
import linkCoin from "../../assets/img/tokens/link.png";
import btcbCoin from "../../assets/img/tokens/btcb.png";
import { ethers } from "ethers";

import * as CONST from "../../constant/test";
import { Common } from "../../redux/common";
import { numberWithCommas, convertEthToWei, exactNumber, convertWeiToEth } from "../../utils";

const fadeInAnimation = keyframes`${fadeIn}`;
const fadeInUpAnimation = keyframes`${fadeInUp}`;

const FadeInDiv = styled.div`
  animation: 1s ${fadeInAnimation};
`;
const FadeInUpDiv = styled.div`
  animation: 1s ${fadeInUpAnimation};
`;

const baseCoinImages = {
  XSK: xskCoin,
  SONIC: SonicCoin,
  XHNW: xhnwCoin,
  TRUNK: trunkCoin,
  BUSD: busdCoin,
  WBNB: wbnbCoin,
  CAKE: cakeCoin,
  DAI: daiCoin,
  ETH: ethCoin,

  ADA: adaCoin,
  LINK: linkCoin,
  BTCB: btcbCoin,
}
const vaults = {
  // 'XGFI': {
  //   vaultAddress: (CONST.CONTRACTS.BNB.VaultV3Contracts.xsofi.vault),
  //   tokenAddress: (CONST.CONTRACTS.BNB.VaultV3Contracts.xsofi.token),
  //   startTimestamp: (CONST.CONTRACTS.BNB.VaultV3Contracts.xsofi.start),
  //   // vaultInstance: new ethers.Contract(vaultAddress, vaultV3ABI),
  //   vaultABI: CONST.vaultV3ABI,
  //   vaultType: 1,
  // },
  'XHNW': {
    vaultAddress: (CONST.CONTRACTS.BNB.VaultV3Contracts.xhnw.vault),
    tokenAddress: (CONST.CONTRACTS.BNB.VaultV3Contracts.xhnw.token),
    startTimestamp: (CONST.CONTRACTS.BNB.VaultV3Contracts.xhnw.start),
    // vaultInstance: new ethers.Contract(vaultAddress, vaultV3ABI),
    vaultABI: CONST.vaultV3ABI,
    vaultType: 1,
  },
  'TRUNK': {
    vaultAddress: (CONST.CONTRACTS.BNB.VaultV3Contracts.trunk.vault),
    tokenAddress: (CONST.CONTRACTS.BNB.VaultV3Contracts.trunk.token),
    startTimestamp: (CONST.CONTRACTS.BNB.VaultV3Contracts.trunk.start),
    // vaultInstance: new ethers.Contract(vaultAddress, vaultV3ABI),
    vaultABI: CONST.vaultV3ABI,
    vaultType: 1,
  },
  // 'WBNB': {
  //   vaultAddress: (CONST.CONTRACTS.BNB.VaultContracts.wbnb.vault),
  //   tokenAddress: (CONST.CONTRACTS.BNB.VaultContracts.wbnb.token),
  //   proxyAddress: (CONST.CONTRACTS.BNB.VaultV1ProxyContracts.WBNB),
  //   startTimestamp: (CONST.CONTRACTS.BNB.VaultContracts.wbnb.start),
  //   // vaultInstance: new ethers.Contract(vaultAddress, vaultABI),
  //   vaultABI: CONST.vaultABI,
  //   vaultType: 2,
  // },
  // 'BUSD': {
  //   vaultAddress: (CONST.CONTRACTS.BNB.VaultContracts.busd.vault),
  //   tokenAddress: (CONST.CONTRACTS.BNB.VaultContracts.busd.token),
  //   proxyAddress: (CONST.CONTRACTS.BNB.VaultV1ProxyContracts.BUSD),
  //   startTimestamp: (CONST.CONTRACTS.BNB.VaultContracts.busd.start),
  //   // vaultInstance: new ethers.Contract(vaultAddress, vaultABI),
  //   vaultABI: CONST.vaultABI,
  //   vaultType: 2,

  // },
  // 'BTCB': {
  //   vaultAddress: (CONST.CONTRACTS.BNB.VaultContracts.btcb.vault),
  //   tokenAddress: (CONST.CONTRACTS.BNB.VaultContracts.btcb.token),
  //   proxyAddress: (CONST.CONTRACTS.BNB.VaultV1ProxyContracts.BTCB),
  //   startTimestamp: (CONST.CONTRACTS.BNB.VaultContracts.btcb.start),
  //   // vaultInstance: new ethers.Contract(vaultAddress, vaultABI),
  //   vaultABI: CONST.vaultABI,
  //   vaultType: 2,
  // },
  'SONIC': {
    vaultAddress: (CONST.CONTRACTS.BNB.VaultContracts.xsk.vault),
    tokenAddress: (CONST.CONTRACTS.BNB.VaultContracts.xsk.token),
    proxyAddress: (CONST.CONTRACTS.BNB.VaultV1ProxyContracts.XSK),
    startTimestamp: (CONST.CONTRACTS.BNB.VaultContracts.xsk.start),
    // vaultInstance: new ethers.Contract(vaultAddress, vaultABI),
    vaultABI: CONST.vaultABI,
    vaultType: 2,
  },
  // 'OGX': {
  //   vaultAddress: (CONST.CONTRACTS.BNB.VaultContracts.ogx.vault),
  //   tokenAddress: (CONST.CONTRACTS.BNB.VaultContracts.ogx.token),
  //   proxyAddress: (CONST.CONTRACTS.BNB.VaultV1ProxyContracts.OGX),
  //   startTimestamp: (CONST.CONTRACTS.BNB.VaultContracts.ogx.start),
  //   // vaultInstance: new ethers.Contract(vaultAddress, vaultABI),
  //   vaultABI: CONST.vaultABI,
  //   vaultType: 2,
  //   // $("#depositCard").hide(), 
  //   // $("#compoundBtnBox").hide(), 
  // },

  // V2 vault checks
  // 'CAKE': {
  //   startTimestamp: 1627595701,
  //   vaultAddress: (CONST.CONTRACTS.BNB.VaultV2Contracts.cake.vault),
  //   tokenAddress: (CONST.CONTRACTS.BNB.Tokens.cake.token),
  //   // vaultInstance: new ethers.Contract(vaultAddress, vaultV2ABI),
  //   vaultABI: CONST.vaultV2ABI,
  //   vaultType: 1,
  // },
  // 'DAI': {
  //   startTimestamp: 1627914660,
  //   vaultAddress: (CONST.CONTRACTS.BNB.VaultV2Contracts.dai.vault),
  //   tokenAddress: (CONST.CONTRACTS.BNB.Tokens.dai.token),
  //   // vaultInstance: new ethers.Contract(vaultAddress, vaultV2ABI),
  //   vaultABI: CONST.vaultV2ABI,
  //   vaultType: 1,
  // },
  'ADA': {
    startTimestamp: 1627914780,
    vaultAddress: (CONST.CONTRACTS.BNB.VaultV2Contracts.ada.vault),
    tokenAddress: (CONST.CONTRACTS.BNB.Tokens.ada.token),
    // vaultInstance: new ethers.Contract(vaultAddress, vaultV2ABI),
    vaultABI: CONST.vaultV2ABI,
    vaultType: 1,
  },
  'ETH': {
    startTimestamp: 1627595701,
    vaultAddress: (CONST.CONTRACTS.BNB.VaultV2Contracts.eth.vault),
    tokenAddress: (CONST.CONTRACTS.BNB.Tokens.eth.token),
    // vaultInstance: new ethers.Contract(vaultAddress, vaultV2ABI),
    vaultABI: CONST.vaultV2ABI,
    vaultType: 1,
  },
  'LINK': {
    startTimestamp: 1627917540,
    vaultAddress: (CONST.CONTRACTS.BNB.VaultV2Contracts.link.vault),
    tokenAddress: (CONST.CONTRACTS.BNB.Tokens.link.token),
    // vaultInstance: new ethers.Contract(vaultAddress, vaultV2ABI),
    vaultABI: CONST.vaultV2ABI,
    vaultType: 1,
  },
  'SONIC': {
    startTimestamp: 1627917540,
    vaultAddress: (CONST.CONTRACTS.BNB.VaultV2Contracts.link.vault),
    tokenAddress: (CONST.CONTRACTS.BNB.Tokens.link.token),
    // vaultInstance: new ethers.Contract(vaultAddress, vaultV2ABI),
    vaultABI: CONST.vaultV2ABI,
    vaultType: 1,
  }
}

const _toShow = 3;
const _places = 2;

export default function Vault() {
  const { account, netWrong } = Common();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [amountDeposit, setAmountDeposit] = useState(0);
  const [amountWithdraw, setAmountWithdraw] = useState(0);
  const [amountTransfer, setAmountTransfer] = useState(0);
  const [recipientAddress, setRecipientAddress] = useState("");
  const [vaultContract, setVaultContract] = useState();
  const [tokenContract, setTokenContract] = useState();
  const [proxyContract, setProxyContract] = useState();

  const [isApprove, setIsApprove] = useState(false);
  const [isDeposit, setIsDeposit] = useState(false);

  const [_tokensInUserWallet, set_tokensInUserWallet] = useState(0);
  const [_myTokens, set_myTokens] = useState();
  const [_vaultAPY, set_vaultAPY] = useState();

  const [_myDividends, set_myDividends] = useState(0);
  const [_Deposited, set_Deposited] = useState(0);
  const [_withdrawn, set_withdrawn] = useState(0);
  // const [_rewarded, set_rewarded] = useState(0);
  const [_compounded, set_compounded] = useState(0);
  // const [_contributed, set_contributed] = useState(0);

  // const [_approvedAmount, set_approvedAmount] = useState();
  const [_totalStaked, set_TotalStaked] = useState(-1);
  const [_totalBalance, set_totalBalance] = useState(-1);
  const [_rewardsFund, set_rewardsFund] = useState(-1);
  const [totalSupply, setTotalSupply] = useState(-1);
  const [playerCount, setPlayerCount] = useState(-1);
  const [totalClaims, setTotalClaims] = useState(-1);
  const [totalDeposits, setTotalDeposits] = useState(-1);
  const [totalTxCount, setTotalTxCount] = useState(-1);

  const [_transferredShares, set_transferredShares] = useState(-1);
  const [_receivedShares, set_receivedShares] = useState(-1);

  const [_xInvested, set_xInvested] = useState(-1);
  // const [_xRewarded, set_xRewarded] = useState(-1);
  // const [_xContributed, set_xContributed] = useState(-1);
  const [_xWithdrawn, set_xWithdrawn] = useState(-1);
  // const [_xTransferredShares, set_xTransferredShares] = useState(-1);
  // const [_xReceivedShares, set_xReceivedShares] = useState(-1);
  const [_xRolled, set_xRolled] = useState(0);

  //  TEXT
  const [depositHint, setDepositHint] = useState("10% Tax applies");
  const [withdrawHint, setWithdrawHint] = useState("10% Tax applies");

  useEffect(() => {
    if (!id || !account) return;
    (async () => {
      const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const signer = provider.getSigner();
      let result;
      const tokenInfo = vaults[id];
      let tokenInstance = new ethers.Contract(tokenInfo.tokenAddress, CONST.tokenABI, signer);
      setTokenContract(tokenInstance);
      let vaultInstance = new ethers.Contract(tokenInfo.vaultAddress, CONST.vaultABI, signer);
      setVaultContract(vaultInstance);

      if (tokenInfo.vaultType === 2) {
        setProxyContract(new ethers.Contract(tokenInfo.proxyAddress, CONST.proxyABI, signer));
      } else {
        setIsApprove(false);
      }

      ////////////////////// Update
      let _toCheck;
      if (tokenInfo.vaultType === 2) {
        _toCheck = tokenInfo.proxyAddress;
      } else {
        _toCheck = tokenInfo.vaultAddress;
      }
      // revoke or deposit
      // if (tokenInfo.vaultType === 2) {
      //   result = await tokenInstance.allowance(account, tokenInfo.vaultAddress);
      //   console.log(result, 'ddddddddd');
      //   if (result > 0) {
      //     setIsApprove(true);
      //   } else {
      //     setIsApprove(false);
      //   }
      // }
      setIsApprove(false)
      // in deposit section, approve or deposit
      // let _approvedAmountVal = (await tokenInstance.allowance(account, _toCheck)) / 1e58;
      await sleep(300);
      let _approvedAmountVal = 0;

      // set_approvedAmount(_approvedAmountVal)
      // console.log("Your approved tokens: " + _approvedAmountVal);
      if (_approvedAmountVal * 1e58 > (1000000000000000 * 1e18)) {
        setIsDeposit(true);
      } else {
        setIsDeposit(false);
      }

      // /////////Stats & Information
      // let _totalStakedVal = ethers.utils.formatEther(await vaultInstance.totalSupply());
      // set_TotalStaked(_totalStakedVal);
      await sleep(300);
      let _totalStakedVal = 0;
      set_TotalStaked(_totalStakedVal);

      // let totalSupplyVal = ethers.utils.formatEther(await vaultInstance.totalSupply());
      // setTotalSupply(totalSupplyVal);
      await sleep(500);
      let totalSupplyVal = 0;
      setTotalSupply(totalSupplyVal);


      // let _totalBalanceVal = ethers.utils.formatEther(await vaultInstance.totalBalance());
      // set_totalBalance(_totalBalanceVal);
      let _totalBalanceVal = 0;
      set_totalBalance(_totalBalanceVal);

      // let _rewardsFundVal = ethers.utils.formatEther(await vaultInstance.dripPoolBalance());
      // set_rewardsFund(_rewardsFundVal);
      let _rewardsFundVal = 0;
      set_rewardsFund(_rewardsFundVal);


      // setPlayerCount(await vaultInstance.users());
      // setTotalClaims(ethers.utils.formatEther(await vaultInstance.totalClaims()));
      // setTotalDeposits(ethers.utils.formatEther(await vaultInstance.totalDeposits()));
      // setTotalTxCount(await vaultInstance.totalTxs());
      setPlayerCount(0);
      setTotalClaims(0);
      setTotalDeposits(0);
      setTotalTxCount(0);

      await sleep(1000);
      // Stats & middle
      // result = await vaultInstance.accountOf(account);
      set_Deposited(0);
      set_withdrawn(0);
      // set_rewarded(ethers.utils.formatEther(result[2]));
      set_compounded(0);
      // set_contributed(ethers.utils.formatEther(result[4]));

      set_transferredShares(0);
      set_receivedShares(0);

      set_xInvested(0);
      // set_xRewarded(result[8]);
      // set_xContributed(result[9]);
      set_xWithdrawn(0);
      // set_xTransferredShares(result[11]);
      // set_xReceivedShares(result[12]);
      set_xRolled(0);
      set_myDividends(0);


      // /////////TKN Vault
      // title-approx & middle info
      // let _myTokensVal = ethers.utils.formatEther(await vaultInstance.myTokens());
      // set_myTokens(_myTokensVal);
      let _myTokensVal = 0;
      set_myTokens(_myTokensVal);
      // title-wallet
      // let _tokensInUserWalletVal = ethers.utils.formatEther(await tokenInstance.balanceOf(account));
      // set_tokensInUserWallet(_tokensInUserWalletVal);
      let _tokensInUserWalletVal = 0;
      set_tokensInUserWallet(_tokensInUserWalletVal);
      // title-APR ~%
      let startTimestamp;
      if (tokenInfo.vaultType === 2) {
        // startTimestamp = Number(await vaultInstance.startTime());
      }
      // let _estimate = ethers.utils.formatEther(await vaultInstance.dailyEstimate(account));   ////nita
      let _estimate = 0;
      let dripApy = (_myTokensVal > 0) ? _estimate * 365 * 100 / _myTokensVal : 0
      let start = startTimestamp;
      let now = Math.floor(Date.now() / 1000);
      let daysRunning = (now - start) / 86400;
      let totalVolume = _rewardsFundVal / 0.08;
      let instantDaily = totalVolume / daysRunning;
      let instantApy = 100 * 365 * 0.02 * instantDaily / totalSupplyVal;
      let apy = instantApy + dripApy;
      console.log("apy", apy);
      if (apy === 0 || Number.isNaN(apy)) {
        set_vaultAPY("calculating...");
      } else {
        set_vaultAPY("~" + numberWithCommas(apy.toFixed(_toShow)) + "% APR");
      }

      setLoading(false);
    })();
  }, [account, id])

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const changeAmountDeposit = (e) => {
    let amount = e.target.value;
    setAmountDeposit(amount);
    if (Number(amount) === 0 || Number.isNaN(amount)) {
      setDepositHint("10% Tax applies");
      return;
    } else if (Number(amount) > 0) {
      setDepositHint("Your Deposit: " + numberWithCommas(Number(amount * 90 / 100).toFixed(_toShow)) + " " + id);
    }
  }
  const changeAmountWithdraw = (e) => {
    let amount = e.target.value;
    setAmountWithdraw(amount);
    if (Number(amount) === 0 || Number.isNaN(amount)) {
      setWithdrawHint("10% Tax applies");
      return;
    } else if (Number(amount) > 0) {
      setWithdrawHint("Your Withdrawal: " + numberWithCommas(Number(amount * 90 / 100).toFixed(_toShow)) + " " + id);
    }
  }

  const changeAmountTransfer = (e) => {
    setAmountTransfer(e.target.value);
  }

  const compoundHandle = async () => {
    if (_myDividends > 0) {
      try {
        let tx = await vaultContract.compound();
        toast.success("Processing Tx - <a href='https://bscscan.com/tx/" + tx.hash + "'>View Tx</a>", {
          position: "top-right",
          theme: "colored",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (err) {
        console.dir(err);
        toast.error("Failed - try again later!", {
          position: "top-right",
          theme: "colored",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.warning("No Dividends - You must have some earnings to do that!", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const harvestHandle = async () => {
    if (_myDividends > 0) {
      try {
        let tx = await vaultContract.harvest();
        toast.success("Processing Tx - <a href='https://bscscan.com/tx/" + tx.hash + "'>View Tx</a>", {
          position: "top-right",
          theme: "colored",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (err) {
        console.dir(err);
        toast.error("Failed - try again later!", {
          position: "top-right",
          theme: "colored",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.warning("No Dividends - You must have some earnings to do that!", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const approveHandle = async () => {
    var amount = CONST.MAX_INT_HEX;
    var _toApprove;

    if (vaults[id].vaultType === 2) {
      _toApprove = vaults[id].proxyAddress;
    } else {
      _toApprove = vaults[id].vaultAddress;
    }

    try {
      await tokenContract.approve(_toApprove, String(amount));
      toast.success("Approving " + id + " Vault", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      console.dir(err);
      toast.error("Failed - try again later!", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const depositHandle = async () => {
    var amount = amountDeposit;
    if (amount > 0) {
      if (vaults[id].vaultType === 2) {
        try {
          let tx = await proxyContract.deposit(String(convertEthToWei(amount)));
          toast.success("Processing Tx - <a href='https://bscscan.com/tx/" + tx.hash + "'>View Tx</a>", {
            position: "top-right",
            theme: "colored",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } catch (error) {
          console.log(error);
          toast.danger("Failed - try again later!", {
            position: "top-right",
            theme: "colored",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        try {
          let tx = await vaultContract.deposit(String(convertEthToWei(amount)));
          toast.success("Processing Tx - <a href='https://bscscan.com/tx/" + tx.hash + "'>View Tx</a>", {
            position: "top-right",
            theme: "colored",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } catch (error) {
          console.log(error);
          toast.danger("Failed - try again later!", {
            position: "top-right",
            theme: "colored",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    }
  }

  const withdrawHandle = async () => {
    var amount = amountWithdraw;
    if (amount > 0) {
      if (vaults[id].vaultType === 1) {
        try {
          let tx = await vaultContract.withdraw(String(convertEthToWei(amount)));
          toast.success("Unstaking " + id + " - <a href='https://bscscan.com/tx/" + tx.hash + "'>View Tx</a>", {
            position: "top-right",
            theme: "colored",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } catch (error) {
          console.log(error);
          toast.error("Failed - try again later!", {
            position: "top-right",
            theme: "colored",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else if (vaults[id].vaultType === 2) {
        try {
          let tx = await vaultContract.resolve(String(convertEthToWei(amount)));
          toast.success("Unstaking " + id + " - <a href='https://bscscan.com/tx/" + tx.hash + "'>View Tx</a>", {
            position: "top-right",
            theme: "colored",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } catch (error) {
          console.log(error);
          toast.error("Failed - try again later!", {
            position: "top-right",
            theme: "colored",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    }
  }

  const transferHandle = async () => {
    var amount = amountTransfer;
    var to = recipientAddress;
    if (amount > 0 && to.length === 42) {
      try {
        let tx = await vaultContract.transfer(to, String(convertEthToWei(amount)));
        toast.success("Transferring " + id + " - <a href='https://bscscan.com/tx/" + tx.hash + "'>View Tx</a>", {
          position: "top-right",
          theme: "colored",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (err) {
        console.log(err);
        toast.error("Failed - try again later!", {
          position: "top-right",
          theme: "colored",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  const maxDepositHandle = () => {
    setAmountDeposit(exactNumber(_tokensInUserWallet, _places));

    toast.warning("Max Deposit - Please be sure you want to do that before confirming!", {
      position: "top-right",
      theme: "colored",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const maxWithdrawHandle = async () => {
    setAmountWithdraw(exactNumber(_myTokens, _places));
    toast.warning("Max Withdraw - Please be sure you want to do that before confirming!", {
      position: "top-right",
      theme: "colored",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  return (
    <Box className="vault-container pt-40 pb-20">
      <Box className="vault-section pb-40 border-bottom-hr">
        <Container>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <FadeInUpDiv className="card1   vault-card">
                {/* <strong className="card-title text-left">TKN Vault</strong> */}
                {
                  loading ?
                    <img src={loaderURL} alt="loading" />
                    :
                    <img src={baseCoinImages[id]} />
                }
                <strong className="sub-title">{id} vault</strong>
                <strong className="sub-title">
                  Wallet: <span id="myWalletBalance">{numberWithCommas(Number(_tokensInUserWallet ? _tokensInUserWallet : 0).toFixed(_toShow)) + ' ' + id}</span> |{" "}
                  <span id="vault-APY">{_vaultAPY}</span>
                </strong>
                <span>
                  You hold approx. <span id="myStakePercentage">{numberWithCommas(Number((_myTokens * 100) / _totalBalance).toFixed(_toShow))}</span>% of
                  this Vault.
                </span>
                <Stack
                  className="sub-card-group border-bottom-hr pb-20"
                  direction="row"
                  justifyContent="space-around"
                >
                  <Box className="sub-card">
                    <img alt="icon" src={icon1URL} />
                    <span>{numberWithCommas(Number(_myTokens ? _myTokens : 0).toFixed(_toShow))}{" "}{id.toUpperCase()}</span>
                    <small>My Stake</small>
                  </Box>
                  <Box className="sub-card">
                    <img alt="icon" src={icon2URL} />
                    <span>{numberWithCommas(Number(_Deposited).toFixed(_toShow))}{" "}{id.toUpperCase()}</span>
                    <small>Deposited</small>
                  </Box>
                  <Box className="sub-card">
                    <img alt="icon" src={icon3URL} />
                    <span>{numberWithCommas(Number(_myDividends).toFixed(_toShow))}{" "}{id.toUpperCase()}</span>
                    <small>Rewards</small>
                  </Box>
                </Stack>
                <Stack
                  className="sub-card-group pb-20 pt-20"
                  direction="row"
                  justifyContent="space-around"
                >
                  <Box className="sub-card">
                    <img alt="icon" src={icon4URL} />
                    <span>{Number(_xRolled)}</span>
                    <small>Compounds</small>
                  </Box>
                  <Box className="sub-card">
                    <img alt="icon" src={icon5URL} />
                    <span>{numberWithCommas(Number(_withdrawn).toFixed(_toShow))}{" "}{id.toUpperCase()}</span>
                    <small>Harvested</small>
                  </Box>
                  <Box className="sub-card">
                    <img alt="icon" src={icon6URL} />
                    <span>{numberWithCommas(Number(_compounded).toFixed(_toShow))}{" "}{id.toUpperCase()}</span>
                    <small>Compounded</small>
                  </Box>
                </Stack>
                <Stack className="btn-group" direction="row">
                  <Link to="">
                    <button className="btn btn-success" onClick={compoundHandle}>
                      <span>
                        <img src={recycleURL} alt="Recycle" />
                        <span>Compound</span>
                      </span>
                    </button>
                  </Link>
                  <Link to="">
                    <button className="btn btn-success" onClick={harvestHandle}>
                      <span>
                        <img src={tractorURL} alt="Tractor" />
                        <span>Harvest</span>
                      </span>
                    </button>
                  </Link>
                </Stack>
              </FadeInUpDiv>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <FadeInUpDiv className="card1   deposit">
                <strong className="card-title text-left">Deposit</strong>
                <br />
                <span>Amount: </span>
                <input
                  id="stake-tokens-amount"
                  className="form-control "
                  type="number"
                  onChange={changeAmountDeposit}
                  value={amountDeposit}
                  placeholder="100"
                />

                <Stack className="btn-group" direction="column">
                  <span>{depositHint}</span>
                  {
                    isApprove ? (
                      <Link to="">
                        <button className="btn btn-danger">
                          <span>
                            <span>REVOKE APPROVAL</span>
                          </span>
                        </button>
                      </Link>
                    ) : (
                      <>
                        {
                          !isDeposit ? (
                            <Link to="">
                              <button className="btn btn-color-waring" onClick={approveHandle}>
                                <span>
                                  <span>Approve</span>
                                </span>
                              </button>
                            </Link>
                          ) : (
                            <>
                              <Link to="">
                                <button className="btn btn-success" onClick={depositHandle}>
                                  <span>
                                    <span>Deposit</span>
                                  </span>
                                </button>
                              </Link>
                              <Link to="">
                                <button className="btn" onClick={maxDepositHandle}>
                                  <span>
                                    <span>MAX</span>
                                  </span>
                                </button>
                              </Link>
                            </>
                          )
                        }
                      </>
                    )
                  }
                </Stack>
              </FadeInUpDiv>
              <FadeInUpDiv className="card1   mt-20 withdraw">
                <strong className="card-title text-left">Withdraw</strong>
                <br />
                <span>Amount: </span>
                <input
                  id="stake-tokens-amount"
                  className="form-control "
                  type="number"
                  placeholder="100"
                  value={amountWithdraw}
                  onChange={changeAmountWithdraw}
                />

                <Stack className="btn-group" direction="column">
                  <span>{withdrawHint}</span>
                  <Link to="">
                    <button className="btn btn-danger" onClick={withdrawHandle}>
                      <span>
                        <span>Withdraw</span>
                      </span>
                    </button>
                  </Link>
                  <Link to="">
                    <button className="btn btn-danger" onClick={maxWithdrawHandle}>
                      <span>
                        <span>MAX</span>
                      </span>
                    </button>
                  </Link>
                </Stack>
              </FadeInUpDiv>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <FadeInUpDiv className="card1   transfer-card">
                <strong className="card-title text-left">Transfer</strong>
                <Stack direction="row" justifyContent="space-between">
                  <Box>
                    <span>Quantity:</span>
                    <input className="form-control" placeholder="100" value={amountTransfer} onChange={changeAmountTransfer} />
                  </Box>
                  <Box>
                    <span>Recipient Address:</span>
                    <input className="form-control" placeholder="0x..." value={recipientAddress} onChange={(e) => setRecipientAddress(e.target.value)} />
                  </Box>
                </Stack>
                <Stack direction="column" alignItems="center">
                  <span>
                    ➡️ Sent:&nbsp;{
                      _transferredShares === -1 ? (
                        <img src={loaderURL} alt="loading" />
                      ) : (
                        numberWithCommas(Number(_transferredShares).toFixed(_toShow))
                      )
                    }&nbsp;&nbsp;|&nbsp;&nbsp;⬅️ Received:&nbsp;{
                      _receivedShares === -1 ? (
                        <img src={loaderURL} alt="loading" />
                      ) : (
                        numberWithCommas(Number(_receivedShares).toFixed(_toShow))
                      )
                    }
                  </span>
                  <Link to="">
                    <button className="btn" onClick={transferHandle}>
                      <span>
                        <span>Transfer</span>
                      </span>
                    </button>
                  </Link>
                </Stack>
              </FadeInUpDiv>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FadeInUpDiv className="card1   stats-card">
                <strong className="card-title text-left">Quick Stats</strong>
                <Stack direction="column" justifyContent="space-between" className="list-wrap">
                  <Box className="border-bottom-hr">
                    <strong>⬇️ Total Deposits</strong>
                    {
                      _xInvested === -1 ? (
                        <img src={loaderURL} alt="loading" />
                      ) : (
                        numberWithCommas(_xInvested)
                      )
                    }
                  </Box>
                  <Box className="border-bottom-hr">
                    <strong>⬆️ Total Withdrawals</strong>
                    {
                      _xWithdrawn === -1 ? (
                        <img src={loaderURL} alt="loading" />
                      ) : (
                        numberWithCommas(_xWithdrawn)
                      )
                    }
                  </Box>
                  <Box className="border-bottom-hr">
                    <strong>👨🏻‍🌾 SOFI</strong>
                    {
                      playerCount === -1 ? (
                        <img src={loaderURL} alt="loading" />
                      ) : (
                        numberWithCommas(playerCount)
                      )
                    }
                  </Box>
                  <Box className="border-bottom-hr">
                    <strong>☝️ Interactions</strong>
                    {
                      totalTxCount === -1 ? (
                        <img src={loaderURL} alt="loading" />
                      ) : (
                        Number(totalTxCount)
                      )
                    }
                  </Box>
                  <Box>
                    <strong>🌊 Rewards Pool Balance</strong>
                    {
                      _rewardsFund === -1 ? (
                        <img src={loaderURL} alt="loading" />
                      ) : (
                        numberWithCommas(Number(_rewardsFund ? _rewardsFund : 0).toFixed(_toShow)) + ' ' + id
                      )
                    }
                  </Box>
                </Stack>
              </FadeInUpDiv>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FadeInUpDiv className="card1   information-card">
                <strong className="card-title text-left">More Stats & Information</strong>
                <Stack direction="column" justifyContent="space-between" className="list-wrap">
                  <Box className="border-bottom-hr">
                    <strong>💰 Total Staked</strong>
                    {
                      _totalStaked === -1 ? (
                        <img src={loaderURL} alt="loading" />
                      ) : (
                        numberWithCommas(Number(_totalStaked).toFixed(_toShow)) + ' ' + id
                      )
                    }
                  </Box>
                  <Box className="border-bottom-hr">
                    <strong>💰 Tokens in Contract</strong>
                    {
                      _totalBalance === -1 ? (
                        <img src={loaderURL} alt="loading" />
                      ) : (
                        numberWithCommas(Number(_totalBalance).toFixed(_toShow)) + ' ' + id
                      )
                    }
                  </Box>
                  <Box className="border-bottom-hr">
                    <strong>💰 Total Deposited</strong>
                    {
                      totalDeposits === -1 ? (
                        <img src={loaderURL} alt="loading" />
                      ) : (
                        numberWithCommas(Number(totalDeposits).toFixed(_toShow)) + ' ' + id
                      )
                    }
                  </Box>
                  <Box className="border-bottom-hr">
                    <strong>💸 Total Harvested</strong>
                    {
                      totalClaims === -1 ? (
                        <img src={loaderURL} alt="loading" />
                      ) : (
                        numberWithCommas(Number(totalClaims).toFixed(_toShow)) + ' ' + id
                      )
                    }
                  </Box>
                  <Box>
                    <strong>🔒 Total Tokens</strong>
                    {
                      totalSupply === -1 ? (
                        <img src={loaderURL} alt="loading" />
                      ) : (
                        numberWithCommas(Number(totalSupply ? totalSupply : 0).toFixed(_toShow)) + ' ' + id
                      )
                    }
                  </Box>
                </Stack>
              </FadeInUpDiv>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {
        netWrong && (
          <FadeInUpDiv className="second-section pt-40 pb-40 border-bottom-hr">
            <Container>
              <WrongNetwork />
            </Container>
          </FadeInUpDiv>
        )
      }
      <FadeInDiv className="third-section pt-40">
        <Container>
          <ContentFooter />
        </Container>
      </FadeInDiv>
    </Box>
  );
}
