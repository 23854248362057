import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import classnames from "classnames";

import "./modal.scss";

export default function Modal(props) {
  const closeModalHandle = (e) => {
    if (e.target.classList.contains("modal-wrap")) props.openToggleHandle();
  };
  
  return (
    <Box className={classnames("modal-wrap", (props.open === true ? " open " : " "))} onClick={closeModalHandle}>
      
      <IconButton aria-label="delete" className="modal-close-btn" onClick={props.openToggleHandle}>
        <CloseIcon fontSize="inherit" />
      </IconButton>
      <Box className={classnames("modal-container", "card1", props.styleName)}>
        {props.children}
      </Box>
    </Box>
  )
}