import { ethers } from "ethers";
import { toast } from "react-toastify";
import * as CONST from "../../constant/test";
import axios from "axios";

import {
  SLIDER_BAR_TOGGLE,
  MODE_TOGGLE,
  FONT_TOGGLE,
  WALLET_CONNECT,
  WALLET_DISCONNECT,
  VIDEO_TOGGLE,
  NET_WRONG,
  GET_PRICE,
  GET_GLOBAL_CONTRACT,
  CONTRACTS_SET_INFORMATION,
  PRINCE_FOOTER_TOGGLE, 
} from "../types";

export const SliderBar_toggle = () => ({
  type: SLIDER_BAR_TOGGLE,
  payload: "",
});

export const Mode_toggle = () => ({
  type: MODE_TOGGLE,
  payload: "",
});

export const Font_toggle = (font) => ({
  type: FONT_TOGGLE,
  payload: font,
});

export const VideoModal_toggle = (data) => ({
  type: VIDEO_TOGGLE,
  payload: data,
});

export const netWrong = (status) => ({
  type: NET_WRONG,
  payload: status
})

export const getPrice = (data) => ({
  type: GET_PRICE,
  payload: data,
});

export const PrinceFooter_toggle = (data) => ({
  type: PRINCE_FOOTER_TOGGLE,
  payload: data,
});

export const wallet_connect = () => async (dispatch) => {
  let provider;
  try {
    provider = new ethers.providers.Web3Provider(window.ethereum, 'any');

    await provider.send("eth_requestAccounts", []);
    const netWork = await provider.getNetwork();
    
    if (netWork.chainId === 1 || netWork.chainId === 56 || netWork.chainId === 25) {
      const signer = provider.getSigner();
      const account = await signer.getAddress();
      const balance = await provider.getBalance(account);
      const balanceInEth = ethers.utils.formatEther(balance);

      // console.log("Account:", account);
      // console.log("Balance:", balance);
      // console.log("BalanceInEth:", balanceInEth);

      dispatch({
        type: WALLET_CONNECT,
        payload: {
          account: account,
          balance: balanceInEth,
          currencySymbol: GetCurrentSymbol(netWork.chainId)
        },
      });
      dispatch(netWrong(false));

      if (!localStorage.getItem("account")) {
        dispatch(VideoModal_toggle(true));
        localStorage.setItem("account", account);
      }

      toast.success('Success!', {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      // ///////////////////////
      // Global Contract start
      // ///////////////////////

      let hourglassContractInstance = new ethers.Contract(CONST.hourglassContractAddress, CONST.hourglassContractABI, signer);

      let nameslistInstance = new ethers.Contract(CONST.namesContractAddress, CONST.namesContractABI, signer);

      let teamSystemInstance = new ethers.Contract(CONST.teamSystemContractAddress, CONST.teamSystemContractABI, signer);

      let treasuryContractInstance = new ethers.Contract(CONST.treasuryContractAddress, CONST.treasuryContractABI, signer);

      let rewardTokenNativeSwapInstance = new ethers.Contract(CONST.rewardTokenNativeSwapAddress, CONST.rewardTokenNativeSwapABI, signer);

      // Token
      let cashInstance = new ethers.Contract(CONST.cashTokenContractAddress, CONST.generalTokenABI, signer);
      let bondInstance = new ethers.Contract(CONST.bondTokenContractAddress, CONST.generalTokenABI, signer);
      let shareInstance = new ethers.Contract(CONST.shareTokenContractAddress, CONST.generalTokenABI, signer);
      let baseInstance = new ethers.Contract(CONST.baseTokenContractAddress, CONST.generalTokenABI, signer);
      let yieldInstance = new ethers.Contract(CONST.rewardTokenAddress, CONST.generalTokenABI, signer);

      // Stacked Token
      let stakedBondsInstance = new ethers.Contract(CONST.stakedBondsAddress, CONST.stakedTokenABI, signer);
      let stakedCashInstance = new ethers.Contract(CONST.stakedCashAddress, CONST.stakedTokenABI, signer);

      dispatch({
        type: GET_GLOBAL_CONTRACT,
        payload: {
          hourglassContractInstance: hourglassContractInstance,
          nameslistInstance: nameslistInstance,
          teamSystemInstance: teamSystemInstance,
          treasuryContractInstance: treasuryContractInstance,
          rewardTokenNativeSwapInstance: rewardTokenNativeSwapInstance,
          cashInstance: cashInstance,
          bondInstance: bondInstance,
          shareInstance: shareInstance,
          baseInstance: baseInstance,
          yieldInstance: yieldInstance,
          stakedBondsInstance: stakedBondsInstance,
          stakedCashInstance: stakedCashInstance,
        }
      })
      ////////////////////////
      // Global Contract end
      ////////////////////////
      console.log("Global END");

      ////////////////////////
      // Global Contract start
      ////////////////////////
      let btcbVault = new ethers.Contract(CONST.btcbVaultAddress, CONST.vaultABI, signer);
      let wbnbVault = new ethers.Contract(CONST.wbnbVaultAddress, CONST.vaultABI, signer);
      let busdVault = new ethers.Contract(CONST.busdVaultAddress, CONST.vaultABI, signer);
      let xskVault = new ethers.Contract(CONST.xskVaultAddress, CONST.vaultABI, signer);
      let ogxVault = new ethers.Contract(CONST.ogxVaultAddress, CONST.vaultABI, signer);

      // v2 Vaults
      let cakeVault = new ethers.Contract(CONST.cakeVaultAddress, CONST.vaultV2ABI, signer);
      let linkVault = new ethers.Contract(CONST.linkVaultAddress, CONST.vaultV2ABI, signer);
      let daiVault = new ethers.Contract(CONST.daiVaultAddress, CONST.vaultV2ABI, signer);
      let ethVault = new ethers.Contract(CONST.ethVaultAddress, CONST.vaultV2ABI, signer);
      let adaVault = new ethers.Contract(CONST.adaVaultAddress, CONST.vaultV2ABI, signer);

      let xgfiVault = new ethers.Contract(CONST.xgfiVaultAddress, CONST.vaultABI, signer);
      let xhnwVault = new ethers.Contract(CONST.xhnwVaultAddress, CONST.vaultABI, signer);
      let trunkVault = new ethers.Contract(CONST.trunkVaultAddress, CONST.vaultABI, signer);

      let btcbToken = new ethers.Contract(CONST.btcbContractAddress, CONST.tokenABI, signer);
      let wbnbToken = new ethers.Contract(CONST.wbnbContractAddress, CONST.tokenABI, signer);
      let busdToken = new ethers.Contract(CONST.busdContractAddress, CONST.tokenABI, signer);
      let xskToken = new ethers.Contract(CONST.xskContractAddress, CONST.tokenABI, signer);
      let ogxToken = new ethers.Contract(CONST.ogxTokenAddress, CONST.tokenABI, signer);
      let skToken = new ethers.Contract(CONST.skContractAddress, CONST.tokenABI, signer);

      let gfiToken = new ethers.Contract(CONST.gfiContractAddress, CONST.tokenABI, signer);
      let xgfiToken = new ethers.Contract(CONST.xgfiContractAddress, CONST.tokenABI, signer);

      let hnwToken = new ethers.Contract(CONST.hnwContractAddress, CONST.tokenABI, signer);
      let xhnwToken = new ethers.Contract(CONST.xhnwContractAddress, CONST.tokenABI, signer);

      let trunkToken = new ethers.Contract(CONST.trunkContractAddress, CONST.tokenABI, signer);

      let stakedToken = new ethers.Contract(CONST.xgfiContractAddress, CONST.stakedTokenABI, signer);

      let shibaInstance = new ethers.Contract(CONST.CONTRACTS.BNB.DEX.Swap.shibaRouter, CONST.dexABI, signer);
      let pancakeInstance = new ethers.Contract(CONST.CONTRACTS.BNB.DEX.Swap.pancakeRouterV2, CONST.dexABI, signer);

      let namesInstance = new ethers.Contract(CONST.namesContractAddress, CONST.namesContractABI, signer);

      // var mgeContractClass = web3js.eth.contract(mgeABI);
      // mgeInstance = mgeContractClass.at(mgeContractAddress);

      // var mgdContractClass = web3js.eth.contract(mgdABI);
      // mgdInstance = mgdContractClass.at(mgdContractAddress);
      dispatch({
        type: GET_GLOBAL_CONTRACT,
        payload: {
          btcbVault: btcbVault,
          wbnbVault: wbnbVault,
          busdVault: busdVault,
          xskVault: xskVault,
          ogxVault: ogxVault,
          cakeVault: cakeVault,
          linkVault: linkVault,
          daiVault: daiVault,
          ethVault: ethVault,
          adaVault: adaVault,
          xgfiVault: xgfiVault,
          xhnwVault: xhnwVault,
          trunkVault: trunkVault,
          btcbToken: btcbToken,
          wbnbToken: wbnbToken,
          busdToken: busdToken,
          xskToken: xskToken,
          ogxToken: ogxToken,
          skToken: skToken,
          gfiToken: gfiToken,
          xgfiToken: xgfiToken,
          hnwToken: hnwToken,
          xhnwToken: xhnwToken,
          trunkToken: trunkToken,
          stakedToken: stakedToken,
          shibaInstance: shibaInstance,
          pancakeInstance: pancakeInstance,
          namesInstance: namesInstance,
        }
      })
      console.log("GLOBAL_CONTRACT_END");
      // // ==========================================================================

      // // TOTAL SUPPLY
      // let btcbInVault = ethers.utils.formatEther(await btcbVault.totalSupply());
      // let wbnbInVault = ethers.utils.formatEther(await wbnbVault.totalSupply());
      // let busdInVault = ethers.utils.formatEther(await busdVault.totalSupply());
      // let xskInVault = ethers.utils.formatEther(await xskVault.totalSupply());
      // let ogxInVault = ethers.utils.formatEther(await ogxVault.totalSupply());

      // let skSupply = ethers.utils.formatEther(await skToken.totalSupply());
      // let xskSupply = ethers.utils.formatEther(await xskToken.totalSupply());
      // let ogxSupply = ethers.utils.formatEther(await ogxToken.totalSupply());

      // // TOTAL SUPPLY v2 Vaults
      // let cakeInVault = ethers.utils.formatEther(await cakeVault.totalSupply());
      // let linkInVault = ethers.utils.formatEther(await linkVault.totalSupply());
      // let ethInVault = ethers.utils.formatEther(await ethVault.totalSupply());
      // let daiInVault = ethers.utils.formatEther(await daiVault.totalSupply());
      // let adaInVault = ethers.utils.formatEther(await adaVault.totalSupply());

      // // TOTAL SUPPLY v3 Vaults
      // let xgfiInVault = ethers.utils.formatEther(await xgfiVault.totalSupply());
      // let xhnwInVault = ethers.utils.formatEther(await xhnwVault.totalSupply());
      // let trunkInVault = ethers.utils.formatEther(await trunkVault.totalSupply());

      // // Total Deposited
      // let btcbDeposited = await btcbVault.totalDeposits();
      // let wbnbDeposited = await wbnbVault.totalDeposits();
      // let busdDeposited = await busdVault.totalDeposits();
      // let xskDeposited = await xskVault.totalDeposits();
      // let ogxDeposited = await ogxVault.totalDeposits();

      // // Total Deposited v1.2 Vaults
      // let cakeDeposited = await cakeVault.totalDeposits();
      // let linkDeposited = await linkVault.totalDeposits();
      // let ethDeposited = await ethVault.totalDeposits();
      // let daiDeposited = await daiVault.totalDeposits();
      // let adaDeposited = await adaVault.totalDeposits();

      // let xgfiDeposited = await xgfiVault.totalDeposits();
      // let xhnwDeposited = await xhnwVault.totalDeposits();
      // let trunkDeposited = await trunkVault.totalDeposits();

      // // Rewards Pool
      // let btcbRewards = await btcbVault.dripPoolBalance();
      // let wbnbRewards = await wbnbVault.dripPoolBalance();
      // let busdRewards = await busdVault.dripPoolBalance();
      // let xskRewards = await xskVault.dripPoolBalance();
      // let ogxRewards = await ogxVault.dripPoolBalance();

      // // Rewards Pool (v1.2 Vaults)
      // let cakeRewards = await cakeVault.dripPoolBalance();
      // let linkRewards = await linkVault.dripPoolBalance();
      // let ethRewards = await ethVault.dripPoolBalance();
      // let daiRewards = await daiVault.dripPoolBalance();
      // let adaRewards = await adaVault.dripPoolBalance();

      // // Rewards Pool (v1.5 Vaults)
      // let xgfiRewards = await xgfiVault.dripPoolBalance();
      // let xhnwRewards = await xhnwVault.dripPoolBalance();
      // let trunkRewards = await trunkVault.dripPoolBalance();

      // // TOTAL SUPPLY
      let xskSupply;
      let xgfiSupply;
      let xhnwSupply;
      try {
        xskSupply = ethers.utils.formatEther(await xskToken.totalSupply());
        // let gfiSupply = ethers.utils.formatEther(await gfiToken.totalSupply());
        xgfiSupply = ethers.utils.formatEther(await xgfiToken.totalSupply());
        
        // let hnwSupply = ethers.utils.formatEther(await hnwToken.totalSupply());
        xhnwSupply = ethers.utils.formatEther(await xhnwToken.totalSupply());
        // let trunkSupply = ethers.utils.formatEther(await trunkToken.totalSupply());
      } catch {
        xskSupply = 0;
        xgfiSupply = 0;
        xhnwSupply = 0;
      }


      dispatch({
        type: CONTRACTS_SET_INFORMATION,
        payload: {
      //     btcbInVault: btcbInVault,
      //     wbnbInVault: wbnbInVault,
      //     busdInVault: busdInVault,
      //     xskInVault: xskInVault,
      //     ogxInVault: ogxInVault,
      //     skSupply: skSupply,
      //     xskSupply: xskSupply,
      //     ogxSupply: ogxSupply,
      //     cakeInVault: cakeInVault,
      //     linkInVault: linkInVault,
      //     ethInVault: ethInVault,
      //     daiInVault: daiInVault,
      //     adaInVault: adaInVault,
      //     xgfiInVault: xgfiInVault,
      //     xhnwInVault: xhnwInVault,
      //     trunkInVault: trunkInVault,
      //     btcbDeposited: btcbDeposited,
      //     wbnbDeposited: wbnbDeposited,
      //     busdDeposited: busdDeposited,
      //     xskDeposited: xskDeposited,
      //     ogxDeposited: ogxDeposited,
      //     cakeDeposited: cakeDeposited,
      //     linkDeposited: linkDeposited,
      //     ethDeposited: ethDeposited,
      //     daiDeposited: daiDeposited,
      //     adaDeposited: adaDeposited,
      //     xgfiDeposited: xgfiDeposited,
      //     xhnwDeposited: xhnwDeposited,
      //     trunkDeposited: trunkDeposited,
      //     btcbRewards: btcbRewards,
      //     wbnbRewards: wbnbRewards,
      //     busdRewards: busdRewards,
      //     xskRewards: xskRewards,
      //     ogxRewards: ogxRewards,
      //     cakeRewards: cakeRewards,
      //     linkRewards: linkRewards,
      //     ethRewards: ethRewards,
      //     daiRewards: daiRewards,
      //     adaRewards: adaRewards,
      //     xgfiRewards: xgfiRewards,
      //     xhnwRewards: xhnwRewards,
      //     trunkRewards: trunkRewards,
      //     gfiSupply: gfiSupply,
          xgfiSupply: xgfiSupply,
      //     hnwSupply: hnwSupply,
          xhnwSupply: xhnwSupply,
      //     trunkSupply: trunkSupply,
        }
      })
      // ==================================================================

      let gfiValue, gfiPrice;
      let xgfiPrice, xgfiValue;
      let xhnwPrice, xhnwValue;
      let trunkPrice, trunkValue;

      let wbnbPrice;
      let btcbPrice;
      let busdPrice;
      let skPrice;
      let xskPrice;
      let hnwPrice;
      let cakePrice;
      let linkPrice;
      let ethPrice;
      let daiPrice;
      let adaPrice;

      let skStaked;
      let gfiStaked;
      let hnwStaked;

      try {
        skStaked = ethers.utils.formatEther(await skToken.balanceOf(CONST.xskContractAddress));
        gfiStaked = ethers.utils.formatEther(await gfiToken.balanceOf(CONST.xgfiContractAddress));
        hnwStaked = ethers.utils.formatEther(await hnwToken.balanceOf(CONST.xhnwContractAddress));
  
        await updateBNBPrice();
        await getGFIPrice();
        await getTRUNKPrice();
      } catch (e) {
        console.log("herere???");
        await sleep(3000);
        skStaked = 0;
        gfiStaked = 0;
        hnwStaked = 0;

        wbnbPrice = 0;
        btcbPrice = 0;
        busdPrice = 0;
        skPrice = 0;
        hnwPrice = 0;
        cakePrice = 0;
        linkPrice = 0;
        ethPrice = 0;
        daiPrice = 0;
        adaPrice = 0;
        gfiValue = 0;
        gfiPrice = 0;
        trunkValue = 0;
        trunkPrice = 0;

        gfiValue = 0;
        gfiPrice = 0;

        trunkValue=0;
        trunkPrice=0;
      }

      try {
        GetPricAdvanced();
      } catch (e) {
        xskPrice = 0;
        xgfiPrice = 0;
        xhnwPrice = 0;
      }
      dispatch({
        type: CONTRACTS_SET_INFORMATION,
        payload: {
          gfiValue: gfiValue,
          xgfiPrice: xgfiPrice,
          xhnwPrice: xhnwPrice,
          trunkPrice: trunkPrice,
          wbnbPrice: wbnbPrice,
          btcbPrice: btcbPrice,
          busdPrice: busdPrice,
          skPrice: skPrice,
          xskPrice: xskPrice,
          hnwPrice: hnwPrice,
          cakePrice: cakePrice,
          linkPrice: linkPrice,
          ethPrice: ethPrice,
          daiPrice: daiPrice,
          adaPrice: adaPrice,
          skStaked: skStaked,
          gfiStaked: gfiStaked,
          hnwStaked: hnwStaked,
          gfiPrice: gfiPrice,
          xgfiValue: xgfiValue,
          xhnwValue: xhnwValue,
          trunkValue: trunkValue,
        }
      })

      let btcbEstimate;
      let btcbEstimateUSD;
      let wbnbEstimate;
      let wbnbEstimateUSD;
      let busdEstimate;
      let busdEstimateUSD;
      let xskEstimate;
      let xskEstimateUSD;
      let ogxEstimate;
      let cakeEstimate;
      let cakeEstimateUSD;
      let linkEstimate;
      let linkEstimateUSD;
      let ethEstimate;
      let ethEstimateUSD;
      let daiEstimate;
      let daiEstimateUSD;
      let adaEstimate;
      let adaEstimateUSD;
      let xgfiEstimate;
      let xgfiEstimateUSD;
      let xhnwEstimate;
      let xhnwEstimateUSD;
      let trunkEstimate;
      let trunkEstimateUSD;
      let myGFIBalance;
      let myxGFIBalance;
      // ==================================Daily Estimate=========================================
      try {
        // Daily Estimate
        btcbEstimate = ethers.utils.formatEther(await btcbVault.dailyEstimate(account));
        btcbEstimateUSD = (btcbEstimate * btcbPrice);
        wbnbEstimate = ethers.utils.formatEther(await wbnbVault.dailyEstimate(account));
        wbnbEstimateUSD = (wbnbEstimate * wbnbPrice);

        busdEstimate = ethers.utils.formatEther(await busdVault.dailyEstimate(account));
        busdEstimateUSD = (busdEstimate * busdPrice);

        xskEstimate = ethers.utils.formatEther(await xskVault.dailyEstimate(account));
        xskEstimateUSD = (xskEstimate * xskPrice);

        ogxEstimate = ethers.utils.formatEther(await ogxVault.dailyEstimate(account));

        // Daily Estimate v1.2 Vaults
        cakeEstimate = ethers.utils.formatEther(await cakeVault.dailyEstimate(account));
        cakeEstimateUSD = (cakeEstimate * cakePrice);

        linkEstimate = ethers.utils.formatEther(await linkVault.dailyEstimate(account));
        linkEstimateUSD = (linkEstimate * linkPrice);

        ethEstimate = ethers.utils.formatEther(await ethVault.dailyEstimate(account));
        ethEstimateUSD = (ethEstimate * ethPrice);

        daiEstimate = ethers.utils.formatEther(await daiVault.dailyEstimate(account));
        daiEstimateUSD = (daiEstimate * daiPrice);

        adaEstimate = ethers.utils.formatEther(await adaVault.dailyEstimate(account));
        adaEstimateUSD = (adaEstimate * adaPrice);

        // Daily Estimate v1.5 Vaults
        xgfiEstimate = ethers.utils.formatEther(await xgfiVault.dailyEstimate(account));
        xgfiEstimateUSD = (xgfiEstimate * xgfiPrice);
        console.log("xgfiEstimateUSD", xgfiEstimateUSD, xgfiPrice);

        xhnwEstimate = ethers.utils.formatEther(await xhnwVault.dailyEstimate(account));
        xhnwEstimateUSD = (xhnwEstimate * xhnwPrice);

        trunkEstimate = ethers.utils.formatEther(await trunkVault.dailyEstimate(account));
        trunkEstimateUSD = (trunkEstimate * trunkPrice);

        // // Progress Bar Thingy
        myGFIBalance = await gfiToken.balanceOf(account);
        myxGFIBalance = await xgfiToken.balanceOf(account);
        // let myHNWBalance = await hnwToken.balanceOf(account);
        // let myxHNWBalance = await xhnwToken.balanceOf(account);
      } catch (e) {
        btcbEstimate=0;
        btcbEstimateUSD=0;
        wbnbEstimate=0;
        wbnbEstimateUSD=0;
        busdEstimate=0;
        busdEstimateUSD=0;
        xskEstimate=0;
        xskEstimateUSD=0;
        ogxEstimate=0;
        cakeEstimate=0;
        cakeEstimateUSD=0;
        linkEstimate=0;
        linkEstimateUSD=0;
        ethEstimate=0;
        ethEstimateUSD=0;
        daiEstimate=0;
        daiEstimateUSD=0;
        adaEstimate=0;
        adaEstimateUSD=0;
        xgfiEstimate=0;
        xgfiEstimateUSD=0;
        xhnwEstimate=0;
        xhnwEstimateUSD=0;
        trunkEstimate=0;
        trunkEstimateUSD=0;
        myGFIBalance=0;
        myxGFIBalance=0;
      }

      dispatch({
        type: CONTRACTS_SET_INFORMATION,
        payload: {
          btcbEstimate: btcbEstimate,
          btcbEstimateUSD: btcbEstimateUSD,
          wbnbEstimate: wbnbEstimate,
          wbnbEstimateUSD: wbnbEstimateUSD,
          busdEstimate: busdEstimate,
          busdEstimateUSD: busdEstimateUSD,
          xskEstimate: xskEstimate,
          xskEstimateUSD: xskEstimateUSD,
          ogxEstimate: ogxEstimate,
          cakeEstimate: cakeEstimate,
          cakeEstimateUSD: cakeEstimateUSD,
          linkEstimate: linkEstimate,
          linkEstimateUSD: linkEstimateUSD,
          ethEstimate: ethEstimate,
          ethEstimateUSD: ethEstimateUSD,
          daiEstimate: daiEstimate,
          daiEstimateUSD: daiEstimateUSD,
          adaEstimate: adaEstimate,
          adaEstimateUSD: adaEstimateUSD,
          xgfiEstimate: xgfiEstimate,
          xgfiEstimateUSD: xgfiEstimateUSD,
          xhnwEstimate: xhnwEstimate,
          xhnwEstimateUSD: xhnwEstimateUSD,
          trunkEstimate: trunkEstimate,
          trunkEstimateUSD: trunkEstimateUSD,
          myGFIBalance: myGFIBalance,
          myxGFIBalance: myxGFIBalance,
          // myHNWBalance: myHNWBalance,
          // myxHNWBalance: myxHNWBalance,
        }
      })
      console.log("Redux Action End!");
      // ==================================HOME START==========================================
      {

        // Total Value Staked
        // let _tvs = (
        //   (btcbInVault / 1e18 * btcbPrice) +
        //   (wbnbInVault / 1e18 * wbnbPrice) +
        //   (busdInVault / 1e18 * busdPrice) +
        //   (cakeInVault / 1e18 * cakePrice) +
        //   (linkInVault / 1e18 * linkPrice) +
        //   (ethInVault / 1e18 * ethPrice) +
        //   (daiInVault / 1e18 * daiPrice) +
        //   (adaInVault / 1e18 * adaPrice) +
        //   (xskInVault / 1e18 * xskPrice) +
        //   (xgfiInVault / 1e18 * xgfiPrice) +
        //   (xhnwInVault / 1e18 * xhnwPrice) +
        //   (trunkInVault / 1e18 * trunkPrice)
        // );

        // let _mcap = (Number(gfiPrice * (gfiSupply / 1e18)));

        // let _tvh = (
        //   ((btcbDeposited / 1e18) * btcbPrice) +
        //   ((wbnbDeposited / 1e18) * wbnbPrice) +
        //   ((busdDeposited / 1e18) * busdPrice) +
        //   ((cakeDeposited / 1e18) * cakePrice) +
        //   ((linkDeposited / 1e18) * linkPrice) +
        //   ((ethDeposited / 1e18) * ethPrice) +
        //   ((daiDeposited / 1e18) * daiPrice) +
        //   ((adaDeposited / 1e18) * adaPrice) +
        //   ((xskDeposited / 1e18) * xskPrice) +
        //   ((xgfiDeposited / 1e18) * xgfiPrice) +
        //   ((xhnwDeposited / 1e18) * xhnwPrice) +
        //   ((trunkDeposited / 1e18) * trunkPrice)
        // );
      }
      // ==================================HOME END============================================




      // ======================================FUNCTION==========================================
      async function updateBNBPrice() {
        let result = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=binancecoin,bitcoin,binance-usd,sidekick-token,pancakeswap-token,chainlink,ethereum,dai,cardano,hobbs-networking&vs_currencies=USD');

        var wbnb = result.data["binancecoin"].usd;
        var busd = result.data["binance-usd"].usd;
        var btcb = result.data["bitcoin"].usd;
        var sk = result.data["sidekick-token"].usd;
        var hnw = result.data["hobbs-networking"].usd;
        var cake = result.data["pancakeswap-token"].usd;
        var link = result.data["chainlink"].usd;
        var eth = result.data["ethereum"].usd;
        var dai = result.data["dai"].usd;
        var ada = result.data["cardano"].usd;

        wbnbPrice = wbnb;
        btcbPrice = btcb;
        busdPrice = busd;

        skPrice = sk;
        hnwPrice = hnw;

        cakePrice = cake;
        linkPrice = link;
        ethPrice = eth;
        daiPrice = dai;
        adaPrice = ada;
      }
      function GetPricAdvanced() {
        xskPrice = ((skStaked * skPrice) / xskSupply);

        xgfiPrice = ((gfiStaked * gfiPrice) / xgfiSupply);

        xhnwPrice = ((hnwStaked * hnwPrice) / xhnwSupply);
      }

      async function getGFIPrice() {
        let result = await shibaInstance.getAmountsIn("1000000000000000000", ["0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c", "0xe727A157757f2d8400108063E9BF9e29EF725Ba6"]);
        gfiValue = result[0] / 1e18;
        gfiPrice = gfiValue * wbnbPrice;
      }

      async function getTRUNKPrice() {
        let result = await pancakeInstance.getAmountsIn("1000000000000000000", ["0xe9e7cea3dedca5984780bafc599bd69add087d56", "0xdd325C38b12903B727D16961e61333f4871A70E0"]);
        trunkValue = result[0] / 1e18;
        trunkPrice = trunkValue * busdPrice;
      }

      function GetCurrentSymbol(chainId) {
        if (chainId == "1") return "ETH"
        else if (chainId == "56") return "BNB"
        else if (chainId == "25") return "CRO"
        else return ""
      }

      function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
      ////////////////////////
      // Dashboard Contract end
      ////////////////////////
    } else {
      dispatch({
        type: WALLET_CONNECT,
        payload: {
          account: "",
          balance: 0,
          currencySymbol: ""
        },
      });
      dispatch(netWrong(true));
      toast.error('Please Switch Network!', {
        theme: "colored",
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  } catch (error) {
    if (!provider) {
      dispatch({
        type: WALLET_CONNECT,
        payload: {
          account: "",
          balance: 0,
          currencySymbol: ""
        },
      });
      dispatch(netWrong(true));
      toast.error("Don't have metamask!", {
        theme: "colored",
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
  }

};

export const wallet_disconnect = () => async (dispatch) => {
  localStorage.setItem("account", "");
  dispatch({
    type: WALLET_DISCONNECT,
    payload: {
      account: "",
      balance: 0,
      currencySymbol: ""
    },
  });
};