import { useEffect, useState } from "react";
import { ethers } from "ethers";

import { Box, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import Modal from "../modal";
import "./transferModal.scss";

import { Common } from "../../redux/common";
import { transferTokens } from "../../utils";
import * as CONST from "../../constant/test";

const baseTokenAddress = CONST.CONTRACTS.BNB.Tokens.sofi.base.token;
const stakedTokenAddress = CONST.CONTRACTS.BNB.Tokens.sofi.staked.token;

export default function TractorModal(props) {
  const { namesInstance } = Common();
  const [amount, setAmount] = useState(0);
  const [receiver, setReceiver] = useState("");

  const [tokenInstance, setTokenInstance] = useState();
  const [stakedTokenInstance, setStakedTokenInstance] = useState();

  useEffect(() => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
    const signer = provider.getSigner();

    setTokenInstance(new ethers.Contract(baseTokenAddress, CONST.baseTokenABI, signer));
    setStakedTokenInstance(new ethers.Contract(stakedTokenAddress, CONST.stakedTokenABI, signer));
  }, [])

  const transferSOFIHandle = async () => {
    transferTokens(receiver, +amount, tokenInstance, namesInstance, "SOFI");
  }
  const transferXSOFIHandle = async () => {
    transferTokens(receiver, +amount, stakedTokenInstance, namesInstance, "xSOFI");
  }

  return (
    <Modal
      styleName="transfer-modal"
      {...props}
    >
      <Box className="modal-header text-center">
        <strong className="modal-title">TRANSFER TOKENS</strong>
      </Box>
      <Box className="modal-content">
        <div className="address-wrap">
          <label>Recipient Address: </label>
          <input className="form-control mt-10 mb-10" type="text" placeholder="Recipient Address" value={receiver} onChange={(e) => setReceiver(e.target.value)} />
        </div>
        <div className="amount-wrap">
          <label>Token Quantity: </label>
          <input className="form-control mt-10 mb-10" type="number" placeholder="Amount to Transfer" value={amount} onChange={(e) => setAmount(e.target.value)} />
        </div>
        <Stack className="pt-210" direction='row' justifyContent="space-between">
          <Link to="">
            <button className="btn btn-success" onClick={transferSOFIHandle}>
              <span>TRANSFER SOFI</span>
            </button>
          </Link>
          <Link to="">
            <button className="btn btn-success" onClick={transferXSOFIHandle}>
              <span>TRANSFER XSOFI</span>
            </button>
          </Link>
        </Stack>
      </Box>
      <Box className="modal-footer">
        <Link to="">
          <button className="btn" onClick={props.openToggleHandle}>
            <span>Close</span>
          </button>
        </Link>
      </Box>
    </Modal>
  );
}