import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from 'react-router-dom';

import "./settingButton.scss";

export default function SettingButton() {
    return (
        <Link className="setting-btn" to="setting">
            <button className="btn">
                <SettingsIcon size="medium" />
            </button>
        </Link>
    )
}