import { Box, Grid, Stack } from "@mui/material";
import { Link } from "react-router-dom";

import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

import airdropURL from "../../assets/img/airdrop.png";
import contractURL from "../../assets/img/cro.png";
import icon1URL from "../../assets/img/icon/1.png";
import icon2URL from "../../assets/img/icon/2.png";
import icon3URL from "../../assets/img/icon/3.png";
import icon4URL from "../../assets/img/icon/4.png";
import icon5URL from "../../assets/img/icon/5.png";
import icon6URL from "../../assets/img/icon/6.png";

import { Container } from "@mui/system";
import ContentFooter from "../../components/contentFooter";
import WrongNetwork from "../../components/wrongNetwork";

import "./stats.scss";

import styled, { keyframes } from "styled-components";
import { fadeIn, fadeInUp } from "react-animations";

const fadeInAnimation = keyframes`${fadeIn}`;
const fadeInUpAnimation = keyframes`${fadeInUp}`;

const FadeInDiv = styled.div`
  animation: 1s ${fadeInAnimation};
`;
const FadeInUpDiv = styled.div`
  animation: 1s ${fadeInUpAnimation};
`;

export default function Stats() {
  return (
    <Box className="stats-container pt-40 pb-20">
      <FadeInDiv className="stats-section pb-40 border-bottom-hr">
        <Container>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <FadeInUpDiv className="card1   lookup">
                <strong className="card-title text-left">Player Lookup</strong>
                <img src={airdropURL} alt="Airdrop" />
                <Stack
                  className="sub-card-group border-bottom-hr pb-20"
                  direction="row"
                  justifyContent="space-around"
                >
                  <Box className="sub-card">
                    <img alt="icon" src={icon1URL} />
                    <span>&nbsp;</span>
                    <small>
                      AMMO = <span className="myBalanceUSD">$0.00</span>
                    </small>
                  </Box>
                  <Box className="sub-card">
                    <img alt="icon" src={icon2URL} />
                    <span>&nbsp;</span>
                    <small>
                      AMMO = <span className="myBalanceUSD">$0.00</span>
                    </small>
                  </Box>
                  <Box className="sub-card">
                    <img alt="icon" src={icon3URL} />
                    <span>(0)</span>
                    <small>
                      AMMO = <span className="myBalanceUSD">$0.00</span>
                    </small>
                  </Box>
                </Stack>
                <Stack
                  className="sub-card-group border-bottom-hr pb-20 pt-20"
                  direction="row"
                  justifyContent="space-around"
                >
                  <Box className="sub-card">
                    <img alt="icon" src={icon4URL} />
                    <span>(0)</span>
                    <small>
                      AMMO = <span className="myBalanceUSD">$0.00</span>
                    </small>
                  </Box>
                  <Box className="sub-card">
                    <img alt="icon" src={icon5URL} />
                    <span>(0)</span>
                    <small>
                      AMMO = <span className="myBalanceUSD">$0.00</span>
                    </small>
                  </Box>
                  <Box className="sub-card">
                    <img alt="icon" src={icon6URL} />
                    <span>(0)</span>
                    <small>
                      AMMO = <span className="myBalanceUSD">$0.00</span>
                    </small>
                  </Box>
                </Stack>
                <Stack direction="column" className="form-group">
                  <label>Enter an Address: </label>
                  <input className="form-control" />
                  <small>
                    Enter an address to view its Hourglass statistics
                  </small>
                  <Stack className="btn-group" direction="row">
                    <Link to="">
                      <button className="btn">
                        <span>Search Stats</span>
                      </button>
                    </Link>
                    <Link to="">
                      <button className="btn">
                        <span>My Stats</span>
                      </button>
                    </Link>
                  </Stack>
                </Stack>
              </FadeInUpDiv>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <FadeInUpDiv className="card1   data">
                <strong className="card-title text-left">Play Data</strong>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item bg-dark text-white">
                    Upline: <span className="userUplineName">___</span>{" "}
                    <small>
                      (<span id="userUpline"> </span>)
                    </small>
                  </li>
                  <li className="list-group-item bg-dark text-white">
                    Downlines: <span id="userDownlines"> </span>
                  </li>
                  <li className="list-group-item bg-dark text-white">
                    Tier: <span id="userTier"> </span>
                  </li>
                </ul>

                <Stack direction="column" className="form-group">
                  <label>Enter an Address: </label>
                  <input className="form-control" />
                  <small>
                    Enter an address to view its Hourglass statistics
                  </small>
                  <Stack className="btn-group" direction="row">
                    <Link to="">
                      <button className="btn">
                        <span>Set as Ipline</span>
                      </button>
                    </Link>
                    <Link to="">
                      <button className="btn btn-success">
                        <span>Airdrop</span>
                      </button>
                    </Link>
                  </Stack>
                </Stack>
              </FadeInUpDiv>
              <FadeInUpDiv className="card1   mt-20 team">
                <strong className="card-title text-left">
                  <PeopleAltIcon />
                  &nbsp;My Team
                </strong>
                <Stack
                  className="sub-card-group pb-20"
                  direction="row"
                  justifyContent="space-around"
                >
                  <Box className="sub-card">
                    <img alt="icon" src={icon1URL} />
                    <strong>0.00</strong>
                    <small>
                      AMMO = <span className="myBalanceUSD">$0.00</span>
                    </small>
                  </Box>
                  <Box className="sub-card">
                    <img alt="icon" src={icon2URL} />
                    <strong>0.00</strong>
                    <small>
                      AMMO = <span className="myBalanceUSD">$0.00</span>
                    </small>
                  </Box>
                  <Box className="sub-card">
                    <img alt="icon" src={icon3URL} />
                    <strong>__</strong>
                    <small>
                      AMMO = <span className="myBalanceUSD">$0.00</span>
                    </small>
                  </Box>
                </Stack>
                <a>
                  <button className="btn btn-warning">About Teams</button>
                </a>
              </FadeInUpDiv>
            </Grid>
            <p className="contentDescription">
              Use this tool to manage your team, your uplines and airdrops to
              other users of the SOFI contract. Look up an address to see its
              details, and optionally set it as your upline, or send it an
              airdrop! Whenever an airdrop is performed, the "Transfer" function
              is used, to send SOFI. Simply enter an amount, then click the
              "Airdrop" button!
            </p>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="card1   contract">
                <strong className="card-title text-left">Contract Stats</strong>
                <Stack direction="row" justifyContent="space-around" className="sub-card-group border-bottom-hr">
                  <Box className="sub-card">
                    <img src={contractURL} alt="contract" />
                    <h3 className="subtitle"><span id="totalDeposited">0.00</span></h3>
                    <p>$<span className="totalDepositedUSD">0.00</span> USD</p>
                    <small>CRO Deposited</small>
                  </Box>
                  <Box className="sub-card">
                    <img src={contractURL} alt="contract" />
                    <h3 className="subtitle"><span className="totalDeposited">0.00</span></h3>
                    <p>$<span className="totalDepositedUSD">0.00</span> USD</p>
                    <small>CRO Withdrawn</small>
                  </Box>
                  <Box className="sub-card">
                    <img src={contractURL} alt="contract" />
                    <h3 className="subtitle"><span className="totalDeposited">0.00</span></h3>
                    <p>$<span className="totalDepositedUSD">0.00</span> USD</p>
                    <small>CRO Compounded</small>
                  </Box>
                  <Box className="sub-card">
                    <img src={contractURL} alt="contract" />
                    <h3 className="subtitle"><span className="totalDeposited">0.00</span></h3>
                    <p>$<span className="totalDepositedUSD">0.00</span> USD</p>
                    <small>CRO Harvested</small>
                  </Box>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </FadeInDiv>
      <FadeInUpDiv className="second-section pt-40 pb-40 border-bottom-hr">
        <Container>
          <WrongNetwork />
        </Container>
      </FadeInUpDiv>
      <FadeInDiv className="third-section pt-40">
        <Container>
          <ContentFooter />
        </Container>
      </FadeInDiv>
    </Box>
  );
}
