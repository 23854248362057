import { Box, Grid, Stack } from "@mui/material";
import { Link } from "react-router-dom";

import profileURL from "../../assets/img/profile.png";
import loaderURL from "../../assets/img/interface/loader.svg";

import { Container } from "@mui/system";
import ContentFooter from "../../components/contentFooter";
import WrongNetwork from "../../components/wrongNetwork";
import { Common } from "../../redux/common";

import "./profile.scss";

import styled, { keyframes } from "styled-components";
import { fadeIn, fadeInUp } from "react-animations";

const fadeInAnimation = keyframes`${fadeIn}`;
const fadeInUpAnimation = keyframes`${fadeInUp}`;

const FadeInDiv = styled.div`
  animation: 1s ${fadeInAnimation};
`;
const FadeInUpDiv = styled.div`
  animation: 1s ${fadeInUpAnimation};
`;

export default function Profile() {
  const { netWrong } = Common();

  return (
    <Box className="profile-container pt-40 pb-20">
      {netWrong && (
        <FadeInUpDiv className="second-section pt-40 pb-40 border-bottom-hr">
          <Container>
            <WrongNetwork />
          </Container>
        </FadeInUpDiv>
      )}
      <FadeInDiv className="third-section pt-40">
        <Container>
          <ContentFooter />
        </Container>
      </FadeInDiv>
    </Box>
  );
}
