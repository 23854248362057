import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import Modal from "../modal";

import QRcodeURL from "../../assets/img/QR.jpg";

import "./receiveTokenModal.scss";
import { Common } from "../../redux/common";
import { shortId, copyToClipboard } from "../../utils";

export default function ReceiveTokenModal(props) {
  const { account } = Common();

  const copyHandle = (e) => {
    copyToClipboard(account);
  }

  return (
    <Modal
      styleName="receive-modal"
      {...props}
    >
      <Box className="modal-header text-center">
        <strong className="modal-title">Receive CRO</strong>
      </Box>
      <Box className="modal-content">
        <img src={QRcodeURL ? QRcodeURL : "https://chart.googleapis.com/chart?chs=350x350&amp;cht=qr&amp;chl=" + account + "&amp;choe=UTF-8"} alt="logo" />
        <strong className="username">{shortId(account, 4)}</strong>
        <small className="address">{shortId(account, 7)}</small>
        <a>
          <button className="btn btn-info" onClick={copyHandle}>
            <span>Copy Address</span>
          </button>
        </a>
      </Box>
      <Box className="modal-footer">
        <Link to="">
          <button className="btn" onClick={props.openToggleHandle}>
            <span>Close</span>
          </button>
        </Link>
      </Box>
    </Modal>
  );
}
