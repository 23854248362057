import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import Modal from "../modal";

import video from "../../assets/mp4/main.mp4";

import "./videomodal.scss";

export default function VideoModal(props) {
  return (
    <Modal styleName="video" {...props}>
      <Box className="modal-header text-center">
        <strong className="modal-title">Welcome</strong>
      </Box>
      <Box className="modal-content">
        <video loop muted autoPlay>
          <source src={video} type="video/mp4" />
        </video>
      </Box>
      <Box className="modal-footer">
        <Link to="">
          <button className="btn" onClick={props.openToggleHandle}>
            <span>Close</span>
          </button>
        </Link>
      </Box>
    </Modal>
  );
}
