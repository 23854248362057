import { Box, Grid, Stack } from "@mui/material";
import { Link } from "react-router-dom";

import sonicURL from "../../assets/img/cards/sonic.png";
import xhnwURL from "../../assets/img/cards/xhnw.png";
import trunkURL from "../../assets/img/cards/trunk.png";
import busdURL from "../../assets/img/cards/busd.png";
import wbnbURL from "../../assets/img/cards/wbnb.png";
import cakeURL from "../../assets/img/cards/cake.png";
import daiURL from "../../assets/img/cards/dai.png";
import ethURL from "../../assets/img/cards/eth.png";

import adaURL from "../../assets/img/cards/ada.png";
import linkURL from "../../assets/img/cards/link.png";
import btcbURL from "../../assets/img/cards/btcb.png";

import { Container } from "@mui/system";
import ContentFooter from "../../components/contentFooter";
import WrongNetwork from "../../components/wrongNetwork";

import "./vaults.scss";

import styled, { keyframes } from "styled-components";
import { fadeIn, fadeInUp } from "react-animations";
import { Common } from "../../redux/common";

const fadeInAnimation = keyframes`${fadeIn}`;
const fadeInUpAnimation = keyframes`${fadeInUp}`;

const FadeInDiv = styled.div`
  animation: 1s ${fadeInAnimation};
`;
const FadeInUpDiv = styled.div`
  animation: 1s ${fadeInUpAnimation};
`;

export default function Vaults() {
  const {netWrong} = Common();
  return (
    <Box className="vaults-container pt-40 pb-20">
      <Box className="vaults-section pb-40 border-bottom-hr">
        <Container>
          <Box className="primary-box">
            <h3 className="subtitle text-center">Primary Vaults</h3>
            <small className=" text-center">
              These vaults are the finest SONIC FINANCE has to offer!
            </small>
            <Grid container className="card-group">            
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FadeInUpDiv className="card1 vault-card">
                  <img alt="sonic image" src={sonicURL} />
                  <Stack className="btn-group" direction="row" spacing={2}>
                    <Link to="/vault/SOFI">
                      <button className="btn btn-success">
                        <span>
                          <span>Manage</span>
                        </span>
                      </button>
                    </Link>
                  </Stack>
                </FadeInUpDiv>
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FadeInUpDiv className="card1   vault-card">
                  <img alt="eth image" src={ethURL} />
                  <Stack className="btn-group" direction="row" spacing={2}>
                    <Link to="/vault/ETH">
                      <button className="btn btn-grey">
                        <span>
                          <span>Manage</span>
                        </span>
                      </button>
                    </Link>
                  </Stack>
                </FadeInUpDiv>
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FadeInUpDiv className="card1   vault-card">
                  <img alt="xhnw image" src={xhnwURL} />
                  <Stack className="btn-group" direction="row" spacing={2}>
                    <Link to="/vault/XHNW">
                      <button className="btn btn-warning">
                        <span>
                          <span>Manage</span>
                        </span>
                      </button>
                    </Link>
                  </Stack>
                </FadeInUpDiv>
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FadeInUpDiv className="card1   vault-card">
                  <img alt="trunk image" src={trunkURL} />
                  <Stack className="btn-group" direction="row" spacing={2}>
                    <Link to="/vault/TRUNK">
                      <button className="btn btn-black">
                        <span>
                          <span>Manage</span>
                        </span>
                      </button>
                    </Link>
                    {/* <Link to="">
                      <button className="btn btn-danger">
                        <span>
                          <span>Exit</span>
                        </span>
                      </button>
                    </Link> */}
                  </Stack>
                </FadeInUpDiv>
              </Grid>
            </Grid>
          </Box>
          <Box className="legacy-box">
            <h3 className="subtitle text-center">Legacy Vaults</h3>
            <small className=" text-center">The earlier offerings of the platform</small>
            <Grid container className="card-group">
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <FadeInUpDiv className="card1   vault-card">
                  <img alt="ada image" src={adaURL} />
                  <Stack className="btn-group" direction="row" spacing={2}>
                    <Link to="/vault/ADA">
                      <button className="btn btn-info">
                        <span>
                          <span>Manage</span>
                        </span>
                      </button>
                    </Link>
                    {/* <Link to="">
                      <button className="btn btn-danger">
                        <span>
                          <span>Exit</span>
                        </span>
                      </button>
                    </Link> */}
                  </Stack>
                </FadeInUpDiv>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <FadeInUpDiv className="card1   vault-card">
                  <img alt="link image" src={linkURL} />
                  <Stack className="btn-group" direction="row" spacing={2}>
                    <Link to="/vault/LINK">
                      <button className="btn btn-warning">
                        <span>
                          <span>Manage</span>
                        </span>
                      </button>
                    </Link>
                    {/* <Link to="">
                      <button className="btn btn-danger">
                        <span>
                          <span>Exit</span>
                        </span>
                      </button>
                    </Link> */}
                  </Stack>
                </FadeInUpDiv>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <FadeInUpDiv className="card1   vault-card">
                  <img alt="btcb image" src={btcbURL} />
                  <Stack className="btn-group" direction="row" spacing={2}>
                    <Link to="/vault/BTCB">
                      <button className="btn btn-orange">
                        <span>
                          <span>Manage</span>
                        </span>
                      </button>
                    </Link>
                    {/* <Link to="">
                      <button className="btn btn-danger">
                        <span>
                          <span>Exit</span>
                        </span>
                      </button>
                    </Link> */}
                  </Stack>
                </FadeInUpDiv>
              </Grid>
              {/* <Grid item lg={3} md={4} sm={6} xs={12}>
                <FadeInUpDiv className="card1   vault-card">
                  <img alt="ogx image" src={ogxURL} />
                  <Stack className="btn-group" direction="row" spacing={2}>
                    <Link to="/vault/OGX">
                      <button className="btn btn-block">
                        <span>
                          <span>Manage</span>
                        </span>
                      </button>
                    </Link>
                    // <Link to="">
                    //   <button className="btn btn-danger">
                    //     <span>
                    //       <span>Exit</span>
                    //     </span>
                    //   </button>
                    // </Link>
                  </Stack>
                </FadeInUpDiv>
              </Grid> */}
            </Grid>
          </Box>
        </Container>
      </Box>
      {
        netWrong && (
          <FadeInUpDiv className="second-section pt-40 pb-40 border-bottom-hr">
            <Container>
              <WrongNetwork />
            </Container>
          </FadeInUpDiv>
        )
      }
      <FadeInDiv className="third-section pt-40">
        <Container>
          <ContentFooter />
        </Container>
      </FadeInDiv>
    </Box>
  );
}
