import Routes from "./config/routers";
import "./App.scss";

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Common } from "./redux/common";

function App() {
  const {font, mode} = Common();

  return (
    <div
      className={"App " + (mode ? "dark-mode " : "") + (font + "-font-mode")}
    >
      <Routes />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
