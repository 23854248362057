import { Box, Grid, Stack } from "@mui/material";
import { Link } from "react-router-dom";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import AddIcon from "@mui/icons-material/Add";

import logoURL from "../../assets/img/logo.gif";
import icon1URL from "../../assets/img/icon/1.png";
import icon2URL from "../../assets/img/icon/2.png";
import icon3URL from "../../assets/img/icon/3.png";
import icon4URL from "../../assets/img/icon/4.png";
import icon5URL from "../../assets/img/icon/5.png";
import icon6URL from "../../assets/img/icon/6.png";

import { Container } from "@mui/system";
import ContentFooter from "../../components/contentFooter";
import WrongNetwork from "../../components/wrongNetwork";

import "./armory.scss";

import styled, { keyframes } from "styled-components";
import { fadeIn, fadeInUp } from "react-animations";

const fadeInAnimation = keyframes`${fadeIn}`;
const fadeInUpAnimation = keyframes`${fadeInUp}`;

const FadeInDiv = styled.div`
  animation: 1s ${fadeInAnimation};
`;
const FadeInUpDiv = styled.div`
  animation: 1s ${fadeInUpAnimation};
`;

export default function Armory() {
  return (
    <Box className="armory-container pt-40 pb-20">
      <Box className="armory-section pb-40 border-bottom-hr">
        <Container>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <FadeInUpDiv className="card1   armory">
                <img src={logoURL} alt="logo" />
                <h3 className="subtitle">SONIC Armory</h3>
                <Stack
                  className="sub-card-group border-bottom-hr pb-20"
                  direction="row"
                  justifyContent="space-around"
                >
                  <Box className="sub-card">
                    <img alt="icon" src={icon1URL} />
                    <strong>Available</strong>
                    <span>0.00</span>
                    <small>
                      AMMO = <span className="myBalanceUSD">$0.00</span>
                    </small>
                  </Box>
                  <Box className="sub-card">
                    <img alt="icon" src={icon2URL} />
                    <strong>Deposits</strong>
                    <span>0.00</span>
                    <small>
                      AMMO = <span className="myBalanceUSD">$0.00</span>
                    </small>
                  </Box>
                  <Box className="sub-card">
                    <img alt="icon" src={icon3URL} />
                    <strong>Claimed</strong>
                    <span>0.00</span>
                    <small>
                      AMMO = <span className="myBalanceUSD">$0.00</span>
                    </small>
                  </Box>
                </Stack>
                <Stack
                  className="sub-card-group pb-20 pt-20"
                  direction="row"
                  justifyContent="space-around"
                >
                  <Box className="sub-card">
                    <img alt="icon" src={icon4URL} />
                    <strong>Rewarded</strong>
                    <span>0.00</span>
                    <small>
                      AMMO = <span className="myBalanceUSD">$0.00</span>
                    </small>
                  </Box>
                  <Box className="sub-card">
                    <img alt="icon" src={icon5URL} />
                    <strong>Max Payout</strong>
                    <span>0.00</span>
                    <small>
                      AMMO = <span className="myBalanceUSD">$0.00</span>
                    </small>
                  </Box>
                  <Box className="sub-card">
                    <img alt="icon" src={icon6URL} />
                    <strong>Team</strong>
                    <span>0.00</span>
                    <small>
                      AMMO = <span className="myBalanceUSD">$0.00</span>
                    </small>
                  </Box>
                </Stack>
                <Stack className="btn-group" direction="row">
                  <Link to="">
                    <button className="btn">
                      <span>
                        <ArrowForwardIcon size="small" />
                        <span>Reload</span>
                      </span>
                    </button>
                  </Link>
                  <Link to="">
                    <button className="btn">
                      <span>
                        <ContentCutIcon size="small" />
                        <span>Empty Clip</span>
                      </span>
                    </button>
                  </Link>
                </Stack>
              </FadeInUpDiv>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <FadeInUpDiv className="card1   deposit">
                <strong className="card-title text-left">$ Deposit AMMO</strong>
                <br />
                <label className="card-title text-left">
                  <b>
                    AMMO to Stake:{" "}
                    <small>
                      <em>
                        (You have{" "}
                        <span className="color2">
                          <span className="myBalance">0.000</span>
                        </span>
                        )
                      </em>
                    </small>
                  </b>
                </label>
                <input
                  id="stake-tokens-amount"
                  className="form-control "
                  type="text"
                  placeholder="AMMO to Stake..."
                />

                <Stack className="btn-group" direction="row">
                  <Link to="">
                    <button className="btn">
                      <span>
                        <AddIcon size="small" />
                        <span>Approve</span>
                      </span>
                    </button>
                  </Link>
                  <Link to="">
                    <button className="btn">
                      <span>
                        <AddIcon size="small" />
                        <span>Deposit</span>
                      </span>
                    </button>
                  </Link>
                  <Link to="">
                    <button className="btn">
                      <span>
                        <AddIcon size="small" />
                        <span>MAX</span>
                      </span>
                    </button>
                  </Link>
                </Stack>
              </FadeInUpDiv>
              <FadeInUpDiv className="card1   mt-20 buddy">
                <strong className="card-title text-left">$ Get a BUDDY</strong>
                <br />
                <p>
                  <strong>Current Buddy:</strong>
                  <span>0x...</span>
                  <br />
                  <strong>Manager:</strong>
                  <span>NONE.</span>
                  <br />
                  <strong>Beneficiary:</strong>
                  <span>NONE.</span>
                  <br />
                  <strong>Last Check-In:</strong>
                  <span>NONE</span>
                  <br />
                  <strong>Inactivity Threshold:</strong>
                  <span>0</span>
                  <br />
                </p>
                <Stack className="tmp-group" direction="row" justifyContent="space-between">
                    <input
                    id="stake-tokens-amount"
                    className="form-control "
                    type="text"
                    placeholder="AMMO to Stake..."
                    />
                    <a><button className="btn">Set Buddy</button></a>
                </Stack>

                <Stack className="btn-group" direction="row">
                  <Link to="">
                    <button className="btn">
                      <span>
                        <span>Shooter Info</span>
                      </span>
                    </button>
                  </Link>
                  <Link to="">
                    <button className="btn">
                      <span>
                        <span>About Armory</span>
                      </span>
                    </button>
                  </Link>
                </Stack>
              </FadeInUpDiv>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
                <FadeInUpDiv className="card1   earn-card">
                    <strong>Earnings Per Day</strong>
                    <h4>0.00</h4>
                    <small className="subdescription">SONIC AMMO</small>
                </FadeInUpDiv>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
                <FadeInUpDiv className="card1   earn-card">
                    <strong>Earnings Per Week</strong>
                    <h4>0.00</h4>
                    <small className="subdescription">SONIC AMMO</small>
                </FadeInUpDiv>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
                <FadeInUpDiv className="card1   earn-card">
                    <strong>Earnings Per Month</strong>
                    <h4>0.00</h4>
                    <small className="subdescription">SONIC AMMO</small>
                </FadeInUpDiv>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
                <FadeInUpDiv className="card1   earn-card">
                    <strong>Earnings Per Year</strong>
                    <h4>0.00</h4>
                    <small className="subdescription">SONIC AMMO</small>
                </FadeInUpDiv>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <FadeInUpDiv className="second-section pt-40 pb-40 border-bottom-hr">
        <Container>
          <WrongNetwork />
        </Container>
      </FadeInUpDiv>
      <FadeInDiv className="third-section pt-40">
        <Container>
          <ContentFooter />
        </Container>
      </FadeInDiv>
    </Box>
  );
}
