import { Outlet } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Common } from "../redux/common";

export default function DefaultLayout() {
  const { sliderToggle } = Common();

  const [wallpaper] = useState(localStorage.getItem("wallpaper"));
  useEffect(() => {
    if (wallpaper) {
      document.querySelector(
        ".content-wrap"
      ).style.backgroundImage = `url(data:image/png;base64,${wallpaper})`;
      document.querySelector(
        ".content-wrap"
      ).style.backgroundSize = "100% 100%";
    }
  }, [wallpaper]);

  return (
    <>
      <Box className={"container-wrap " + (sliderToggle ? "slider-collapse" : "")}>
        <Box className="content-wrap">
          <Header />
          <Outlet />
          <Footer />
        </Box>
      </Box>
    </>
  );
}
