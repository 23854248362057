import { Box, Stack } from "@mui/material";
import { Link } from "react-router-dom";

import logoURL from "../../assets/img/1.png";

import "./wrongNetwork.scss";

export default function ContentFooter() {
  return (
    <Box className="card1 wrongNetwork-container lightBorder">
      <Stack direction="column" alignItems="center">
        <img src={logoURL}  alt="logo"/>
        {/* <h3 className="subtitle error">UNSUPPORTED NETWORK</h3> */}
        <small className="subdescription">
          Please switch to any of the supported chains, to use the Sonic
          Finance protocol!
        </small>
        {/* <Link to="">
          <button className="btn btn-success">Add Cronos (CRO)</button>
        </Link> */}
        <Link to="/setting">
          <button className="btn">All Supported Chains in the future</button>
        </Link>
      </Stack>
    </Box>
  );
}
