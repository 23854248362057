import { Box, Grid, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { ethers } from "ethers";
import { transferTokens } from "../../utils";

import gfi from "../../assets/img/tokens/gfi.png";
import aut from "../../assets/img/tokens/aut.png";

import loaderURL from "../../assets/img/interface/loader.svg";
import icon1URL from "../../assets/img/icon/machine.png";
import icon2URL from "../../assets/img/icon/lock.png";
import icon3URL from "../../assets/img/icon/8.png";
import icon4URL from "../../assets/img/icon/1.png";
import icon5URL from "../../assets/img/icon/tint.png";
import icon6URL from "../../assets/img/icon/3.png";

import { Container } from "@mui/system";
import ContentFooter from "../../components/contentFooter";
import WrongNetwork from "../../components/wrongNetwork";
import Twitter from "../../components/twitter";
import SellModal from "../../components/sellModal";
import BuyModal from "../../components/buyModal";
import * as CONST from "../../constant/test";

import "./dashboard.scss";

import styled, { keyframes } from "styled-components";
import { fadeInRight, fadeInUp } from "react-animations";
import { Common } from "../../redux/common";
import { useEffect, useState } from "react";

const fadeInRightAnimation = keyframes`${fadeInRight}`;
const fadeInUpAnimation = keyframes`${fadeInUp}`;

const FadeInRightDiv = styled.div`
  animation: 1s ${fadeInRightAnimation};
`;
const FadeInUpDiv = styled.div`
  animation: 1s ${fadeInUpAnimation};
`;

export default function Dashboard() {
  const { netWrong, account, currencySymbol, gfiToken, xgfiToken, gfiValue, gfiPrice,
    btcbEstimateUSD, wbnbEstimateUSD, busdEstimateUSD, xskEstimateUSD, xgfiEstimateUSD, xhnwEstimateUSD, trunkEstimateUSD, cakeEstimateUSD, linkEstimateUSD, ethEstimateUSD, daiEstimateUSD, adaEstimateUSD,
    btcbVault, wbnbVault, busdVault, xskVault, ogxVault, cakeVault, linkVault, ethVault, daiVault, adaVault, xgfiVault, xhnwVault, trunkVault,
    btcbPrice, wbnbPrice, busdPrice, cakePrice, linkPrice, ethPrice, daiPrice, adaPrice, xskPrice, xgfiPrice, xhnwPrice, trunkPrice,
    namesInstance,
  } = Common();
  const [myGFIBalance, setMyGFIBalance] = useState(-1);
  const [myGFIBalanceUSD, setMyGFIBalanceUSD] = useState(-1);
  const [myStakedGFIBalance, setMyStakedGFIBalance] = useState(-1);
  const [myStakedGFIBalanceUSD, setMyStakedGFIBalanceUSD] = useState(-1);
  const [currentVaultEarning, setCurrentVaultEarning] = useState(-1);
  const [stakedInVaults, setStakedInVaults] = useState(-1);
  const [recipentAddress, setRecipentAddress] = useState("");
  const [amount, setAmount] = useState(0);

  const [isShowBuyModal, setIsShowBuyModal] = useState(false);
  const [isShowSellModal, setIsShowSellModal] = useState(false);

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    (async () => {
      if (!currencySymbol || !account || !gfiToken || !xgfiToken) return;
      if (!window.ethereum) return;

      try {
        var result = ethers.utils.formatEther(await gfiToken.balanceOf(account));
        setMyGFIBalance(Number(result).toFixed(0));
        setMyGFIBalanceUSD(Number(result).toFixed(2));
        result = ethers.utils.formatEther(await xgfiToken.balanceOf(account));
        setMyStakedGFIBalance(Number(result).toFixed(0));
        setMyStakedGFIBalanceUSD(Number(result).toFixed(2));

        // ////////////////////////////////////////////////////
        // /////////SHOW Current Vault Earnings////////////////
        // ////////////////////////////////////////////////////
        // USER DIVIDENDS
        let btcbDividends = ethers.utils.formatEther(await btcbVault.dividendsOf(account));
        let wbnbDividends = ethers.utils.formatEther(await wbnbVault.dividendsOf(account));
        let busdDividends = ethers.utils.formatEther(await busdVault.dividendsOf(account));
        let xskDividends = ethers.utils.formatEther(await xskVault.dividendsOf(account));
        let ogxDividends = ethers.utils.formatEther(await ogxVault.dividendsOf(account));
  
        // USER DIVIDENDS v2 Vaults
        let cakeDividends = ethers.utils.formatEther(await cakeVault.dividendsOf(account));
        let linkDividends = ethers.utils.formatEther(await linkVault.dividendsOf(account));
        let ethDividends = ethers.utils.formatEther(await ethVault.dividendsOf(account));
        let daiDividends = ethers.utils.formatEther(await daiVault.dividendsOf(account));
        let adaDividends = ethers.utils.formatEther(await adaVault.dividendsOf(account));
  
        // USER DIVIDENDS v3 Vaults
        let xgfiDividends = ethers.utils.formatEther(await xgfiVault.dividendsOf(account));
        let xhnwDividends = ethers.utils.formatEther(await xhnwVault.dividendsOf(account));
        let trunkDividends = ethers.utils.formatEther(await trunkVault.dividendsOf(account));
        setCurrentVaultEarning((btcbDividends * btcbPrice) +
          (wbnbDividends * wbnbPrice) +
          (busdDividends * busdPrice) +
          (cakeDividends * cakePrice) +
          (linkDividends * linkPrice) +
          (ethDividends * ethPrice) +
          (daiDividends * daiPrice) +
          (adaDividends * adaPrice) +
          (xskDividends * xskPrice) +
          (xgfiDividends * xgfiPrice) +
          (xhnwDividends * xhnwPrice) +
          (trunkDividends * trunkPrice));
  
  
        // //////////////////////////////////////////////
        // /////////SHOW Staked in Vaults////////////////
        // //////////////////////////////////////////////
  
        // USER BALANCE
        let btcbBalance = ethers.utils.formatEther(await btcbVault.balanceOf(account));
        let wbnbBalance = ethers.utils.formatEther(await wbnbVault.balanceOf(account));
        let busdBalance = ethers.utils.formatEther(await busdVault.balanceOf(account));
        let xskBalance = ethers.utils.formatEther(await xskVault.balanceOf(account));
        let ogxBalance = ethers.utils.formatEther(await ogxVault.balanceOf(account));
  
        // USER BALANCE v2 Vaults
        let cakeBalance = ethers.utils.formatEther(await cakeVault.balanceOf(account));
        let linkBalance = ethers.utils.formatEther(await linkVault.balanceOf(account));
        let ethBalance = ethers.utils.formatEther(await ethVault.balanceOf(account));
        let daiBalance = ethers.utils.formatEther(await daiVault.balanceOf(account));
        let adaBalance = ethers.utils.formatEther(await adaVault.balanceOf(account));
  
        // USER BALANCE v3 Vaults
        let xgfiBalance = ethers.utils.formatEther(await xgfiVault.balanceOf(account));
        let xhnwBalance = ethers.utils.formatEther(await xhnwVault.balanceOf(account));
        let trunkBalance = ethers.utils.formatEther(await trunkVault.balanceOf(account));
  
        setStakedInVaults(
          (btcbBalance * btcbPrice) +
          (wbnbBalance * wbnbPrice) +
          (busdBalance * busdPrice) +
          (cakeBalance * cakePrice) +
          (linkBalance * linkPrice) +
          (ethBalance * ethPrice) +
          (daiBalance * daiPrice) +
          (adaBalance * adaPrice) +
          (xskBalance * xskPrice) +
          (xgfiBalance * xgfiPrice) +
          (xhnwBalance * xhnwPrice) +
          (trunkBalance * trunkPrice));
      } catch (e) {
        console.log("hererere==>");
        await sleep(500);
        // var result = ethers.utils.formatEther(await gfiToken.balanceOf(account));
        var result = 0;
        setMyGFIBalance(Number(result).toFixed(0));
        setMyGFIBalanceUSD(Number(result).toFixed(2));
        // result = ethers.utils.formatEther(await xgfiToken.balanceOf(account));
        await sleep(500);
        result = 0;
        setMyStakedGFIBalance(Number(result).toFixed(0));
        setMyStakedGFIBalanceUSD(Number(result).toFixed(2));

        // ////////////////////////////////////////////////////
        // /////////SHOW Current Vault Earnings////////////////
        // ////////////////////////////////////////////////////
        // USER DIVIDENDS
        await sleep(500);
        let btcbDividends = 0;
        let wbnbDividends = 0;
        let busdDividends = 0;
        let xskDividends = 0;
        let ogxDividends = 0;
  
        // USER DIVIDENDS v2 Vaults
        await sleep(500);
        let cakeDividends = 0;
        let linkDividends = 0;
        let ethDividends = 0;
        let daiDividends = 0;
        let adaDividends = 0;
  
        // USER DIVIDENDS v3 Vaults
        await sleep(500);
        let xgfiDividends = 0;
        let xhnwDividends = 0;
        let trunkDividends = 0;
        setCurrentVaultEarning(0);
  
  
        // //////////////////////////////////////////////
        // /////////SHOW Staked in Vaults////////////////
        // //////////////////////////////////////////////
  
        // USER BALANCE
        await sleep(500);
        let btcbBalance = 0;
        let wbnbBalance = 0;
        let busdBalance = 0;
        let xskBalance = 0;
        let ogxBalance = 0;
  
        // USER BALANCE v2 Vaults
        let cakeBalance = 0;
        let linkBalance = 0;
        let ethBalance = 0;
        let daiBalance = 0;
        let adaBalance = 0;
  
        // USER BALANCE v3 Vaults
        await sleep(500);
        let xgfiBalance = 0;
        let xhnwBalance = 0;
        let trunkBalance = 0;

        setStakedInVaults(0);
      }
    })();
  }, [currencySymbol, account, trunkPrice]);

  const transferGFIHandle = () => {
    transferTokens(recipentAddress, +amount, gfiToken, namesInstance, "SOFI");
  }
  const transferXGFIHandle = () => {
    transferTokens(recipentAddress, +amount, xgfiToken, namesInstance, "xSOFI");
  }

  return (
    <Box className="dashboard-container pt-40 pb-20">
      <Box className="pb-40 border-bottom-hr">
        <Container>
          <Grid container spacing={2} columns={12}>
            <Grid className="anaylze-wrap" item lg={7} md={7} sm={12} xs={12}>
              {/* <FadeInUpDiv className="card1   actionCard">
                <img src={gfi} alt="SOFI" />
                <Stack direction="column">
                  <strong>
                    <span className="myXGFIBalance">0.00</span> xSOFI
                    <small>
                      ($<span className="myXGFIBalanceUSD">0.00</span>USD)
                    </small>
                  </strong>
                  <small>My SOFI</small>
                  <div>
                    <a>
                      <button className="btn">Buy</button>
                    </a>
                    <a>
                      <button className="btn">Sell</button>
                    </a>
                    <a>
                      <button className="btn">Stake</button>
                    </a>
                  </div>
                </Stack>
              </FadeInUpDiv>
              <FadeInUpDiv className="card1   actionCard">
                <img src={gfi} alt="SOFI" />
                <Stack direction="column">
                  <strong>
                    <span className="myXGFIBalance">0.00</span> xSOFI
                    <small>
                      ($<span className="myXGFIBalanceUSD">0.00</span>USD)
                    </small>
                  </strong>
                  <small>My xSOFI</small>
                  <div>
                    <a>
                      <button className="btn">Unstake</button>
                    </a>
                    <a>
                      <button className="btn">Transfer</button>
                    </a>
                  </div>
                </Stack>
              </FadeInUpDiv> */}

              <FadeInUpDiv className="card1 dashboard-first">
                <strong className="card-title text-left">
                  Welcome back, {account ? (account.slice(0, 4) + "..." + account.slice(-2)) : ""}
                </strong>
                <Stack
                  className="sub-card-group border-bottom-hr pb-20"
                  direction="row"
                  justifyContent="space-around"
                >
                  <Box className="sub-card">
                    <img alt="icon" src={icon1URL} />
                    <strong>
                      {
                        myGFIBalance === -1 ? (
                          <img className="load" src={loaderURL} alt="loading" />
                        ) : (
                          <span>{myGFIBalance} SOFI</span>
                        )
                      }
                    </strong>
                    <span>
                      {
                        myGFIBalanceUSD === -1 ? (
                          <img className="load" src={loaderURL} alt="loading" />
                        ) : (
                          <>
                            ${myGFIBalanceUSD}<span> USD</span>
                          </>
                        )
                      }
                    </span>
                    <small>My SOFI</small>
                  </Box>
                  <Box className="sub-card">
                    <img alt="icon" src={icon2URL} />
                    <strong>
                      {
                        myStakedGFIBalance === -1 ? (
                          <img className="load" src={loaderURL} alt="loading" />
                        ) : (
                          <span>{myStakedGFIBalance} xSOFI</span>
                        )
                      }
                    </strong>
                    <span>
                      {
                        myStakedGFIBalanceUSD === -1 ? (
                          <img className="load" src={loaderURL} alt="loading" />
                        ) : (
                          <>
                            ${myStakedGFIBalanceUSD}<span> USD</span>
                          </>
                        )
                      }
                    </span>
                    <small>My xSOFI</small>
                  </Box>
                  <Box className="sub-card">
                    <img alt="icon" src={icon3URL} />
                    <strong>
                      {
                        typeof gfiPrice === 'undefined' ? (
                          <img className="load" src={loaderURL} alt="loading" />
                        ) : (
                          <>
                            <span>$&nbsp;{gfiPrice ? Number(gfiPrice).toFixed(2) : "0.00"}</span> SOFI
                          </>
                        )
                      }
                    </strong>
                    <span>
                      {
                        typeof gfiValue === 'undefined' ? (
                          <img className="load" src={loaderURL} alt="loading" />
                        ) : (
                          <>
                            {gfiValue ? Number(gfiValue).toFixed(5) : "0.00"}<span> {currencySymbol}</span>
                          </>
                        )
                      }
                    </span>
                    <small>SOFI Price</small>
                  </Box>
                </Stack>
                <Stack
                  className="sub-card-group pb-20 pt-20"
                  direction="row"
                  justifyContent="space-around"
                >
                  <Box className="sub-card">
                    <img alt="icon" src={icon4URL} />
                    <strong>
                      {
                        typeof adaEstimateUSD === 'undefined' ? (
                          <img className="load" src={loaderURL} alt="loading" />
                        ) : (
                          <>
                            <span>{Number(btcbEstimateUSD + wbnbEstimateUSD + busdEstimateUSD + xskEstimateUSD + xgfiEstimateUSD + xhnwEstimateUSD + trunkEstimateUSD + cakeEstimateUSD + linkEstimateUSD + ethEstimateUSD + daiEstimateUSD + adaEstimateUSD).toFixed(2)}</span>
                          </>
                        )
                      }
                    </strong>
                    <span></span>
                    <small>Daily Vault Income</small>
                  </Box>
                  <Box className="sub-card">
                    <img alt="icon" src={icon5URL} />
                    <strong>
                      {
                        currentVaultEarning === -1 ? (
                          <img className="load" src={loaderURL} alt="loading" />
                        ) : (
                          <>
                            <span>{currentVaultEarning}&nbsp;USD</span>
                          </>
                        )
                      }
                    </strong>
                    <span></span>
                    <small>Current Vault Earnings</small>
                  </Box>
                  <Box className="sub-card">
                    <img alt="icon" src={icon6URL} />
                    <strong>
                      {
                        stakedInVaults === -1 ? (
                          <img className="load" src={loaderURL} alt="loading" />
                        ) : (
                          <>
                            <span>{stakedInVaults}&nbsp;USD</span>
                          </>
                        )
                      }
                    </strong>
                    <span></span>
                    <small>Staked in Vaults</small>
                  </Box>
                </Stack>
                <Stack className="btn-group" direction="row">
                  <Link to="">
                    <button className="btn" onClick={() => setIsShowBuyModal(true)}>
                      <span>
                        <span>BUY</span>
                      </span>
                    </button>
                  </Link>
                  <Link to="">
                    <button className="btn" onClick={() => setIsShowSellModal(true)}>
                      <span>
                        <span>SELL</span>
                      </span>
                    </button>
                  </Link>
                  {/* <Link to="https://sidekick.finance/DefiWatcher?token=0x19F233925DFA7aca402B93aA32F6083B9221a40c" target="_blank">
                    <button className="btn">
                      <span>
                        <span>EXPLORE</span>
                      </span>
                    </button>
                  </Link> */}
                </Stack>
              </FadeInUpDiv>
              <FadeInUpDiv className="card1 dashboard-second mt-2">
                <strong className="card-title text-left">
                  Transfer Tokens
                </strong>
                <Stack
                  className="tmp-wrap"
                  direction={{ lg: "row", md: "row", sm: "row", xs: "column" }}
                  justifyContent={"space-around"}
                  spacing={1}
                >
                  <Stack direction="column">
                    <label>Transfer Amount</label>
                    <input className="form-control" type="number" placeholder="1000" value={amount} onChange={(e) => { setAmount(e.target.value) }} />
                    <Link to="">
                      <button className="btn btn-success" onClick={transferGFIHandle}>TRANSFER SOFI</button>
                    </Link>
                  </Stack>
                  <Stack direction="column">
                    <label>Recipient Address</label>
                    <input className="form-control" type="text" placeholder="0x..." value={recipentAddress} onChange={(e) => { setRecipentAddress(e.target.value) }} />
                    <Link to="">
                      <button className="btn btn-danger" onClick={transferXGFIHandle}>TRANSFER XSOFI</button>
                    </Link>
                  </Stack>
                </Stack>
              </FadeInUpDiv>
            </Grid>
            <Grid item lg={5} md={5} sm={12} xs={12}>
              <FadeInRightDiv className="card1   actionCard twitterCard">
                <Twitter />
              </FadeInRightDiv>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {
        netWrong && (
          <FadeInUpDiv className="second-section pt-40 pb-40 border-bottom-hr">
            <Container>
              <WrongNetwork />
            </Container>
          </FadeInUpDiv>
        )
      }
      <Box className="third-section pt-40">
        <Container>
          <ContentFooter />
        </Container>
      </Box>
      <SellModal open={isShowSellModal} openToggleHandle={setIsShowSellModal}></SellModal>
      <BuyModal open={isShowBuyModal} openToggleHandle={setIsShowBuyModal}></BuyModal>
    </Box>
  );
}
