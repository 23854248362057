import { useState } from "react";
import normal from "normal";
import { toast } from "react-toastify";

import { Box, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import Modal from "../modal";
import "./buyModal.scss";

import { Common } from "../../redux/common";
import { convertEthToWei } from "../../utils";
import * as CONST from "../../constant/test";

export default function BuyModal(props) {
  const { account, balance, gfiValue, shibaInstance } = Common();
  const [amount, setAmount] = useState(0);
  const [amountByBNB, setAmountByBNB] = useState(0);
  const [percentage, setPercentage] = useState(0);

  const changeAmountHandle = (e) => {
    setAmount(e.target.value);
  }

  const changePercentageHandle = (e) => {
    let percent = e.target.value;
    if (percent > 95) return;
    setPercentage(percent);
    setAmount(Number(balance) * percent / 100);
    setAmountByBNB((Number(balance) / gfiValue).toLocaleString('fullwide', { useGrouping: false }));
  }

  const maxBuyHandle = () => {
    let toUse = Number(balance) * 0.95;
    setAmount(toUse);
    setPercentage(95);
    toast.warning("Max Buy - Please be sure you want to do that before confirming!", {
      position: "top-right",
      theme: "colored",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const buyHandle = async () => {
    let xvalue = convertEthToWei(amount);
    console.log("SPENDING " + amount + " SH33P ON CRO");
    try {
      let tx = await shibaInstance.swapExactETHForTokensSupportingFeeOnTransferTokens(
        convertEthToWei(0),
        [CONST.CONTRACTS.BNB.Tokens.wbnb.token, CONST.CONTRACTS.BNB.Tokens.sofi.base.token],
        account,
        (Date.now() + 60), {
        value: xvalue
      });
      toast.success("Deposit Tx - <a href='https://bscscan.com/tx/" + tx.hash + "'>View Tx</a", {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      console.dir(err);
      let errText = "Faild!";
      if (err.code === "UNEXPECTED_ARGUMENT") errText = "TOO MANY ARGUMENTS!";
      toast.error(errText, {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  return (
    <Modal
      styleName="buy-modal"
      {...props}
    >
      <Box className="modal-header text-center">
        <strong className="modal-title">BUY SOFI</strong>
      </Box>
      <Box className="modal-content">
        <Stack className="amount-wrap" direction="column" justifyContent="space-between">
          <label>ETH to Spend: </label>
          <input className="form-control" type="number" placeholder="SOFI Amount" value={amount} onChange={changeAmountHandle} />
        </Stack>
        <Stack className="amount-analyze pt-10" direction="row" justifyContent="space-between">
          <span>{amount} ETH</span>
          <span> = approx. {(amount / gfiValue).toLocaleString('fullwide', { useGrouping: false })} SOFI</span>
        </Stack>
        <div className="pt-10">
          <Stack className="percentage-wrap" direction="column" justifyContent="space-between">
            <label>Percent to Spend: </label>
            <input className="" type="range" value={percentage} onChange={changePercentageHandle} />
          </Stack>
          <span className="text-center pt-10">{percentage}% of your ETH</span>
        </div>
        <p className="smallText pt-10">Use the slider to choose how much ETH to spend!</p>

        <Link className="pt-20" to="">
          <button className="btn btn-success" onClick={buyHandle}>
            <span>BUY SOFI</span>
          </button>
        </Link>
        <Link className="pt-10" to="">
          <button className="btn btn-warning" onClick={maxBuyHandle}>
            <span>MAX</span>
          </button>
        </Link>
        <p className="pt-20">How does this Work?</p>
        <p className="smallText pt-10">By using this dialog, you're purchasing SOFI via Uniswap.<br />
          Slippage is automatically detected for efficiency of transaction.</p>
        <p className="smallText pt-10">All you have to do is enter an amount of ETH to spend, then click "Buy SOFI" - it's that easy!</p>
        <Link className="pt-20" to="https://app.uniswap.org/#/swap" target="_blank" alt="togoLink">
          <button className="btn btn-info">
            <span>GO TO DEX (ADVANCED)</span>
          </button>
        </Link>
      </Box>
      <Box className="modal-footer">
        <Link to="">
          <button className="btn" onClick={props.openToggleHandle}>
            <span>Close</span>
          </button>
        </Link>
      </Box>
    </Modal>
  );
}