import { Box, Grid, Stack } from "@mui/material";
import { Link } from "react-router-dom";

import BarChartIcon from '@mui/icons-material/BarChart';
import logoURL from "../../assets/img/logo.gif";
import croURL from "../../assets/img/tokens/CRO.png";
import loaderURL from "../../assets/img/interface/loader.svg";

import { Container } from "@mui/system";
import ContentFooter from "../../components/contentFooter";
import WrongNetwork from "../../components/wrongNetwork";

import "./swap.scss";

import styled, { keyframes } from "styled-components";
import { fadeIn, fadeInUp } from "react-animations";

const fadeInAnimation = keyframes`${fadeIn}`;
const fadeInUpAnimation = keyframes`${fadeInUp}`;

const FadeInDiv = styled.div`
  animation: 1s ${fadeInAnimation};
`;
const FadeInUpDiv = styled.div`
  animation: 1s ${fadeInUpAnimation};
`;

export default function SWap() {
  return (
    <Box className="swap-container pt-40 pb-20">
      <FadeInDiv className="swap-section pb-40 border-bottom-hr">
        <Container>
          <Stack direction="column" alignItems="center">
            {/* <img className="logo" src={logoURL} alt="logo" />
            <h3 className="subtitle">Stake & Earn</h3>
            <small className="subdescription">
              Stake LP tokens to earn with SONIC FINANCE and partnered
              projects
            </small> */}
            <Grid container spacing={2} className="pt-20">
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <FadeInUpDiv className="card1   swap-card">
                  <h3 className="subtitle">
                    <font color="#11D477">Buy AMMO</font>
                  </h3>
                  <div className="tmp-group">
                    <span>Amount</span>
                    <span>...</span>
                  </div>
                  <input className="form-control" type="number" />
                  <small>0.00</small>
                  <Link to="">
                    <button className="btn btn-success"><span>BUY 0 AMMO</span></button>
                  </Link>
                </FadeInUpDiv>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <FadeInUpDiv className="card1   swap-card">
                  <h3 className="subtitle">
                    <font color="#FF4D4F">Sell AMMO</font>
                  </h3>
                  <div className="tmp-group">
                    <span>Amount</span>
                    <span>...</span>
                  </div>
                  <input className="form-control" type="number" />
                  <small>0.00</small>
                  <Link to="">
                    <button className="btn btn-danger"><span>SELL 0 CRO</span></button>
                  </Link>
                </FadeInUpDiv>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FadeInUpDiv className="card1   stats-card">
                    <strong><BarChartIcon /><span> Swap Stats</span></strong>
                    <Stack direction="row" justifyContent={"space-around"}>
                        <Box className="stats-group">
                            <img src={logoURL}  alt="logo" />
                            <span className="loading">
                                <img src={loaderURL} alt="loading"/>
                            </span>
                            <small>LP Providers</small>
                        </Box>
                        <Box className="stats-group">
                            <img src={logoURL}  alt="logo" />
                            <span className="loading">
                                <img src={loaderURL} alt="loading"/>
                            </span>
                            <small>LP Providers</small>
                        </Box>
                        <Box className="stats-group">
                            <img src={croURL}  alt="CRO" />
                            <span className="loading">
                                <img src={loaderURL} alt="loading"/>
                            </span>
                            <small>LP Providers</small>
                        </Box>
                    </Stack>
                </FadeInUpDiv>
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </FadeInDiv>
      <FadeInUpDiv className="second-section pt-40 pb-40 border-bottom-hr">
        <Container>
          <WrongNetwork />
        </Container>
      </FadeInUpDiv>
      <FadeInDiv className="third-section pt-40">
        <Container>
          <ContentFooter />
        </Container>
      </FadeInDiv>
    </Box>
  );
}
