import { Box, Grid, Stack } from "@mui/material";
import { Link } from "react-router-dom";

import logoURL from "../../assets/img/logo.gif";
import xgfiURL from "../../assets/img/pools/xgfi.png";
import xsheepURL from "../../assets/img/pools/xsheep.png";
import xgaurURL from "../../assets/img/pools/xgaur.png";
import xsmgURL from "../../assets/img/pools/xsmg.png";

import { Container } from "@mui/system";
import ContentFooter from "../../components/contentFooter";
import WrongNetwork from "../../components/wrongNetwork";

import "./staking.scss";

import styled, { keyframes } from "styled-components";
import { fadeIn, fadeInUp } from "react-animations";
import { Common } from "../../redux/common";

const fadeInAnimation = keyframes`${fadeIn}`;
const fadeInUpAnimation = keyframes`${fadeInUp}`;

const FadeInDiv = styled.div`
  animation: 1s ${fadeInAnimation};
`;
const FadeInUpDiv = styled.div`
  animation: 1.5s ${fadeInUpAnimation};
`;

export default function Staking() {
  const { netWrong } = Common();

  return (
    <Box className="staking-container pt-40 pb-20">
      <FadeInDiv className="staking-section pb-40 border-bottom-hr">
        <Container>
          <Stack direction="column" alignItems="center">
            <img className="logo" src={logoURL} alt="logo" />
            <h3 className="subtitle">Stake & Earn</h3>
            <small className="subdescription">
              Stake LP tokens to earn with SONIC FINANCE and partnered projects
            </small>
            <Grid container spacing={2} className="pt-20">
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <FadeInUpDiv className="stake-card">
                  <Link to="/stake/?token=SOFI">
                    <img src={xgfiURL} alt="XGFI" />
                  </Link>
                  <strong>
                    Gaur Finance <em>(xSOFI)</em>
                  </strong>
                  <Box className="card1 lightThinBorder "></Box>
                </FadeInUpDiv>
              </Grid>
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <FadeInUpDiv className="stake-card">
                  <Link to="/stake/?token=GAUR">
                    <img src={xsheepURL} alt="XSHEEP" />
                  </Link>
                  <strong>
                    Gaur Money <em>(xGAUR)</em>
                  </strong>
                  <Box className="card1 lightThinBorder "></Box>
                </FadeInUpDiv>
              </Grid>
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <FadeInUpDiv className="stake-card">
                  <Link to="/stake/?token=SH33P">
                    <img src={xgaurURL} alt="XGAUR" />
                  </Link>
                  <strong>
                    Degen Protocol <em>(xSH33P)</em>
                  </strong>
                  <Box className="card1 lightThinBorder "></Box>
                </FadeInUpDiv>
              </Grid>
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <FadeInUpDiv className="stake-card">
                  <Link to="/stake/?token=SMG">
                    <img src={xsmgURL} alt="XSMG" />
                  </Link>
                  <strong>
                    SONIC FINANCE <em>(xSMG)</em>
                  </strong>
                  <Box className="card1 lightThinBorder "></Box>
                </FadeInUpDiv>
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </FadeInDiv>
      {netWrong && (
        <FadeInUpDiv className="second-section pt-40 pb-40 border-bottom-hr">
          <Container>
            <WrongNetwork />
          </Container>
        </FadeInUpDiv>
      )}
      <FadeInDiv className="third-section pt-40">
        <Container>
          <ContentFooter />
        </Container>
      </FadeInDiv>
    </Box>
  );
}
