import {
  SLIDER_BAR_TOGGLE,
  MODE_TOGGLE,
  FONT_TOGGLE,
  WALLET_CONNECT,
  WALLET_DISCONNECT,
  VIDEO_TOGGLE,
  NET_WRONG,
  GET_PRICE,
  GET_GLOBAL_CONTRACT,
  CONTRACTS_SET_INFORMATION,
  PRINCE_FOOTER_TOGGLE,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case SLIDER_BAR_TOGGLE:
      return {
        ...state,
        sliderToggle: !state.sliderToggle,
      };
    case MODE_TOGGLE:
      return {
        ...state,
        mode: !state.mode,
      };
    case FONT_TOGGLE:
      return {
        ...state,
        font: action.payload,
      };
    case WALLET_CONNECT:
      return {
        ...state,
        ...action.payload,
      };
    case WALLET_DISCONNECT:
      return {
        ...state,
        ...action.payload,
      };
    case VIDEO_TOGGLE:
      return {
        ...state,
        videoToggle: action.payload
      };
    case NET_WRONG:
      return {
        ...state,
        netWrong: action.payload
      }
    case GET_PRICE:
      return {
        ...state,
        ...action.payload
      }
    case GET_GLOBAL_CONTRACT:
      return {
        ...state,
        ...action.payload
      }
    case CONTRACTS_SET_INFORMATION:
      return {
        ...state,
        ...action.payload
      }
    case PRINCE_FOOTER_TOGGLE:
      return {
        ...state,
        ...action.payload
      }
    default:
      return {
        ...state,
      };
  }
};
