import { useEffect } from "react";

export default function Twitter() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;
    // script.onload = () => this.scriptLoaded();

    document.body.appendChild(script);
  }, []);

  return (
    <a
      className="twitter-timeline moveIntoTweet"
      data-tweet-limit="1"
      href="https://twitter.com/sonicfinance_"
    >
      Tweets by SONIC FINANCE
    </a>
  );
}
