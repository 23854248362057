import { Box, Grid, Stack } from "@mui/material";
import { Link } from "react-router-dom";


import logoURL from "../../assets/img/logo.gif";
import bondURL from "../../assets/img/bond.png";
import croURL from "../../assets/img/cro.png";
import loaderURL from "../../assets/img/interface/loader.svg";

import icon1URL from "../../assets/img/icon/1.png";
import icon2URL from "../../assets/img/icon/2.png";
import icon3URL from "../../assets/img/icon/3.png";
import icon4URL from "../../assets/img/icon/4.png";
import icon5URL from "../../assets/img/icon/5.png";
import icon6URL from "../../assets/img/icon/6.png";

import { Container } from "@mui/system";
import ContentFooter from "../../components/contentFooter";
import WrongNetwork from "../../components/wrongNetwork";

import "./reserve.scss";

import styled, { keyframes } from "styled-components";
import { fadeIn, fadeInUp } from "react-animations";

const fadeInAnimation = keyframes`${fadeIn}`;
const fadeInUpAnimation = keyframes`${fadeInUp}`;

const FadeInDiv = styled.div`
  animation: 1s ${fadeInAnimation};
`;
const FadeInUpDiv = styled.div`
  animation: 1s ${fadeInUpAnimation};
`;

export default function Reserve() {
  return (
    <Box className="reserve-container pt-40 pb-20">
      <Box className="reserve-section pb-40 border-bottom-hr">
        <Container>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <FadeInUpDiv className="card1   reserve-card">
                <strong className="card-title text-left">
                  SOFI Reserve
                </strong>
                <img src={logoURL}  alt="logo" />
                <strong className="sub-title">SOFI Reserve</strong>
                <span>Collateral Management for Bonds and Yield</span>
                <Stack
                  className="sub-card-group border-bottom-hr border-top-hr pb-20 pt-20"
                  direction="row"
                  justifyContent="space-around"
                >
                  <Box className="sub-card">
                    <img alt="icon" src={icon1URL} />
                    <span>0.00</span>
                    <small>
                      AMMO = <span className="myBalanceUSD">$0.00</span>
                    </small>
                  </Box>
                  <Box className="sub-card">
                    <img alt="icon" src={icon2URL} />
                    <span>0.00</span>
                    <small>
                      AMMO = <span className="myBalanceUSD">$0.00</span>
                    </small>
                  </Box>
                  <Box className="sub-card">
                    <img alt="icon" src={icon3URL} />
                    <span>0.00</span>
                    <small>
                      AMMO = <span className="myBalanceUSD">$0.00</span>
                    </small>
                  </Box>
                </Stack>
                <Stack
                  className="sub-card-group pb-20 pt-20"
                  direction="row"
                  justifyContent="space-around"
                >
                  <Box className="sub-card">
                    <img alt="icon" src={icon4URL} />
                    <span>0.00</span>
                    <small>
                      AMMO = <span className="myBalanceUSD">$0.00</span>
                    </small>
                  </Box>
                  <Box className="sub-card">
                    <img alt="icon" src={icon5URL} />
                    <span>0.00</span>
                    <small>
                      AMMO = <span className="myBalanceUSD">$0.00</span>
                    </small>
                  </Box>
                  <Box className="sub-card">
                    <img alt="icon" src={icon6URL} />
                    <span>0.00</span>
                    <small>
                      AMMO = <span className="myBalanceUSD">$0.00</span>
                    </small>
                  </Box>
                </Stack>
                <Stack className="btn-group" direction="row">
                  <Link to="">
                    <button className="btn btn-success">
                      <span>
                        <span>Claim Rewards</span>
                      </span>
                    </button>
                  </Link>
                  <Link to="">
                    <button className="btn btn-info">
                      <span>
                        <span>Make it Rain</span>
                      </span>
                    </button>
                  </Link>
                </Stack>
              </FadeInUpDiv>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <FadeInUpDiv className="card1   mint-card">
                <strong className="card-title text-left">
                  Mint/Redeem Bonds
                </strong>
                <br />
                <label className="card-title text-left">
                  <b>
                    WCRO to Deposit: (You have{" "}
                    <span className="userBaseBalance">0.00</span>)
                  </b>
                </label>
                <input
                  id="stake-tokens-amount"
                  className="form-control "
                  type="text"
                  placeholder="Quantity to Deposit..."
                />
                <small>
                  1 WCRO = 1 BOND on Mint, 1 BOND = 0.9 WCRO on Redeem
                </small>

                <Stack className="btn-group" direction="row">
                  <Link to="">
                    <button className="btn">
                      <span>
                        <span>Enable WCRO</span>
                      </span>
                    </button>
                  </Link>
                  <Link to="">
                    <button className="btn">
                      <span>
                        <span>Mint</span>
                      </span>
                    </button>
                  </Link>
                  <Link to="">
                    <button className="btn btn-danger">
                      <span>
                        <span>Redeem</span>
                      </span>
                    </button>
                  </Link>
                </Stack>
              </FadeInUpDiv>
              <FadeInUpDiv className="card1   mt-20 mint-card">
                <strong className="card-title text-left">
                  Stake/Unstake Bonds
                </strong>
                <br />
                <label className="card-title text-left">
                  <b>
                    BOND Amount: (You have{" "}
                    <span className="userBondBalance">0.00</span>)
                  </b>
                </label>
                <input
                  id="stake-tokens-amount"
                  className="form-control "
                  type="text"
                  placeholder="Quantity to Deposit..."
                />
                <small>
                  Enter an amount of BOND to stake, or xBOND to unstake
                </small>

                <Stack className="btn-group" direction="row">
                  <Link to="">
                    <button className="btn">
                      <span>
                        <span>Enable WCRO</span>
                      </span>
                    </button>
                  </Link>
                  <Link to="">
                    <button className="btn btn-success">
                      <span>
                        <span>Stake</span>
                      </span>
                    </button>
                  </Link>
                  <Link to="">
                    <button className="btn btn-danger">
                      <span>
                        <span>Redeem</span>
                      </span>
                    </button>
                  </Link>
                </Stack>
              </FadeInUpDiv>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <FadeInUpDiv className="card1   treasury-card">
                <strong>SOFI Protocol Treasury</strong>

                <Stack className="tmp-group border-bottom-hr" direction="row" justifyContent="space-around">
                    <Box>
                        <img src={bondURL} alt="bond" />
                        <img  className="loading" src={loaderURL} alt="loading"/>
                        <strong>&nbsp;</strong>
                        <small>BOND Supply</small>
                    </Box>
                    <Box>
                        <img src={croURL} alt="cro" />
                        <img  className="loading" src={loaderURL} alt="loading"/>
                        <strong>WCRD</strong>
                        <small>Withdrawable</small>
                    </Box>
                    <Box>
                        <img src={croURL} alt="cro" />
                        <img  className="loading" src={loaderURL} alt="loading"/>
                        <strong>WCRD</strong>
                        <small>Total Collateral</small>
                    </Box>
                </Stack>
                <Link to="">
                  <button className="btn">
                    <span>
                      <span>View Contract</span>
                    </span>
                  </button>
                </Link>
              </FadeInUpDiv>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <FadeInUpDiv className="second-section pt-40 pb-40 border-bottom-hr">
        <Container>
          <WrongNetwork />
        </Container>
      </FadeInUpDiv>
      <FadeInDiv className="third-section pt-40">
        <Container>
          <ContentFooter />
        </Container>
      </FadeInDiv>
    </Box>
  );
}
