import { useState, useEffect } from "react";
import { Box, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { ethers } from "ethers";

import gfiURL from "../../assets/img/tokens/gfi.png";

import { Container } from "@mui/system";
import ContentFooter from "../../components/contentFooter";
import WrongNetwork from "../../components/wrongNetwork";

import "./claim.scss";

import styled, { keyframes } from "styled-components";
import { fadeInUp } from "react-animations";
import { Common } from "../../redux/common";

const fadeInUpAnimation = keyframes`${fadeInUp}`;
const FadeInUpDiv = styled.div`
  animation: 1s ${fadeInUpAnimation};
`;

export default function Claim() {
  const { netWrong, account, currencySymbol, gfiToken } = Common();
  const [myGFIBalance, setMyGFIBalance] = useState(0);

  useEffect(() => {
    (async () => {
      if (!currencySymbol || !account || !gfiToken) return;
      if (!window.ethereum) return;

      var result = ethers.utils.formatEther(await gfiToken.balanceOf(account));
      setMyGFIBalance(Number(result).toFixed(0));
    })();
  }, [currencySymbol, account, gfiToken]);

  return (
    <Box className="claim-container pt-40 pb-20">
      <Box className="claim-section pb-40 border-bottom-hr">
        <Container>
          <FadeInUpDiv className="card1  ">
            <Stack
              className="claim-card"
              direction="column"
              alignItems="center"
            >
              <strong className="card-title text-left">
                Claim new SOFI Tokens
              </strong>
              <img src={gfiURL} alt="GFI" />
              <strong className="sofi-name">Claim your SOFI</strong>
              <small>Get ready for a mammoth-sized migration!</small>
              <div className=" border-bottom-hr" />
              <h3 className="subtitle">
                <font id="userGFIPotential1" color="#0BE780">
                  {Number(myGFIBalance).toFixed(2)}
                </font>{" "}
                SOFI
              </h3>
              <small>Your SOFI Balance</small>
              <div className=" border-bottom-hr" />
              <small>
                Snapshot of SOFI, xSOFI and Vault balances was taken on xx/xx/2023.
              </small>
              <div className="border-bottom-hr" />
              {/* <Link to="">
                <button className="btn btn-success">Claim my Tokens</button>
              </Link> */}
              <small>
                <i>
                  ⚠ Your SOFI will be valueluess after migration! ⚠
                </i>
              </small>
            </Stack>
          </FadeInUpDiv>
        </Container>
      </Box>
      <Box className="second-section pt-40 pb-40 border-bottom-hr">
        <Container>
          <WrongNetwork />
        </Container>
      </Box>
      <Box className="third-section pt-40">
        <Container>
          <ContentFooter />
        </Container>
      </Box>
    </Box>
  );
}
