import { useSelector } from "react-redux";

export function Common() {
  var data = useSelector((state) => state.data);

  data.mode = true;
  data.font = data.font ? data.font : "sofi";
  data.videoOpen = Boolean(data.videoOpen);

  return {
    ...data
  }
}