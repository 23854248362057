import { Box, Grid, Stack } from "@mui/material";
import { Link, useParams } from "react-router-dom";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import loaderURL from "../../assets/img/interface/loader.svg";
import logoURL from "../../assets/img/logo.gif";
import icon1URL from "../../assets/img/icon/1.png";
import icon2URL from "../../assets/img/icon/3.png";
import icon3URL from "../../assets/img/icon/4.png";
import icon4URL from "../../assets/img/icon/7.png";
import icon5URL from "../../assets/img/icon/8.png";
import icon6URL from "../../assets/img/icon/1.png";
import tintURL from "../../assets/img/icon/tint.png";
import refreshURL from "../../assets/img/icon/9.png";

import { Container } from "@mui/system";
import ContentFooter from "../../components/contentFooter";
import WrongNetwork from "../../components/wrongNetwork";
import TransferModal from "../../components/transferModal";

import "./stake.scss";

import styled, { keyframes } from "styled-components";
import { fadeIn, fadeInUp } from "react-animations";
import { Common } from "../../redux/common";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import * as CONST from "../../constant/test";
import { convertWeiToEth, convertEthToWei, numberWithCommas } from "../../utils";
import { toast } from "react-toastify";

const fadeInAnimation = keyframes`${fadeIn}`;
const fadeInUpAnimation = keyframes`${fadeInUp}`;

const FadeInDiv = styled.div`
  animation: 1s ${fadeInAnimation};
`;
const FadeInUpDiv = styled.div`
  animation: 1s ${fadeInUpAnimation};
`;

const baseTokenAddress = CONST.CONTRACTS.BNB.Tokens.sofi.base.token;
const stakedTokenAddress = CONST.CONTRACTS.BNB.Tokens.sofi.staked.token;
const feeSplitterAddress = CONST.CONTRACTS.BNB.Sofi.TOKENOMICS.feeSplitter;

export default function Stake() {
  const { token } = useParams();
  const { netWrong, account, xgfiPrice, xgfiSupply } = Common();
  const [userGFIBalance, setUserGFIBalance] = useState(0);
  const [totalStakedGFI, setTotalStakedGFI] = useState(-1);
  const [myStakedBalance, setMyStakedBalance] = useState(-1);
  const [myStakedBalanceUSD, setMyStakedBalanceUSD] = useState(-1);
  const [xGFIUserPercent, setXGFIUserPercent] = useState(-1);
  const [approvalState, setApprovalState] = useState(false);
  const [baseToStakedAmount, setBaseToStakedAmount] = useState(-1);
  const [stakedToBaseAmount, setStakedToBaseAmount] = useState(-1);
  const [userTotalDeposited, setUserTotalDeposited] = useState(0);
  const [userTotalWithdrawn, setUserTotalWithdrawn] = useState(0);
  const [stakeAmount, setStakeAmount] = useState();
  const [unstakeAmount, setUnstakeAmount] = useState("");
  const [baseToken, setBaseToken] = useState();
  const [stakedToken, setStakedToken] = useState();
  const [totalDeposited, setTotalDeposited] = useState(-1);
  const [totalWithdrawn, setTotalWithdrawn] = useState(-1);
  const [feesplitterBalance, setFeesplitterBalance] = useState(-1);
  const [feeSplitterInstance, setFeeSplitterInstance] = useState();
  const [totalHolders, setTotalHolders] = useState(0);
  const [totalStakers, setTotalStakers] = useState(0);
  const [isShowTransferModal, setIsShowTransferModal] = useState();

  useEffect(() => {
    if (!account || !xgfiPrice || !xgfiSupply) return;
    (async () => {
      const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const signer = provider.getSigner();
      let result;

      let tokenInstance = new ethers.Contract(baseTokenAddress, CONST.baseTokenABI, signer);
      let stakedTokenInstance = new ethers.Contract(stakedTokenAddress, CONST.stakedTokenABI, signer);
      setBaseToken(tokenInstance);
      setStakedToken(stakedTokenInstance);
      setFeeSplitterInstance(new ethers.Contract(feeSplitterAddress, CONST.feeSplitterABI, signer));


      // Stake SOFI & Unstake xSOFI
      let userSpendableGFI = await tokenInstance.allowance(account, stakedTokenAddress);
      console.log("APPROVE: ", userSpendableGFI);
      setApprovalState(Number(userSpendableGFI) > 1e25);

      setUserGFIBalance(ethers.utils.formatEther(await tokenInstance.balanceOf(account)));
      setTotalStakedGFI(ethers.utils.formatEther(await tokenInstance.balanceOf(stakedTokenAddress)));
      result = ethers.utils.formatEther(await stakedTokenInstance.balanceOf(account));
      setMyStakedBalance(result);
      setMyStakedBalanceUSD(result * xgfiPrice);

      let _myPercentageVal = result * 100 / xgfiSupply;
      if (_myPercentageVal === 0 || Number.isNaN(_myPercentageVal)) {
        setXGFIUserPercent("...");
        return;
      } else {
        setXGFIUserPercent(Number(_myPercentageVal).toFixed(2));
      }

      setBaseToStakedAmount(ethers.utils.formatEther(await stakedTokenInstance.baseToStaked("1000000000000000000")));
      setStakedToBaseAmount(ethers.utils.formatEther(await stakedTokenInstance.stakedToBase("1000000000000000000")));

      // Token / Staking Statistics
      setTotalDeposited(ethers.utils.formatEther(await stakedTokenInstance.allTimeStaked()));
      setTotalWithdrawn(ethers.utils.formatEther(await stakedTokenInstance.allTimeUnstaked()));
      result = await stakedTokenInstance.statsOf(account);
      setUserTotalDeposited(ethers.utils.formatEther(result[0]));
      setUserTotalWithdrawn(ethers.utils.formatEther(result[1]));

      setFeesplitterBalance(ethers.utils.formatEther(await tokenInstance.balanceOf(CONST.CONTRACTS.BNB.Sofi.TOKENOMICS.feeSplitter)));
      console.log(ethers.utils.formatEther(await tokenInstance.balanceOf(CONST.CONTRACTS.BNB.Sofi.TOKENOMICS.feeSplitter)), 9999999999999);

      setTotalHolders(Number(await tokenInstance.numberOfTokenHolders()));
      setTotalStakers(Number(await stakedTokenInstance.totalStakers()));
    })();
  }, [account, xgfiPrice, xgfiSupply]);

  const makeItRainHandle = async () => {
    if (convertEthToWei(feesplitterBalance) > 100e18) {
      try {
        await feeSplitterInstance.payFees(CONST.CONTRACTS.BNB.Tokens.sofi.base.token);
        toast.success('FeeSplitter is distributing its SOFI balance...!', {
          position: "top-right",
          theme: "colored",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (err) {
        console.log(err)
        toast.error("That didn't work - try again!", {
          position: "top-right",
          theme: "colored",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.warning('✋ Not enough fees collected yet!', {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const ApproveHandle = async () => {
    let amount = (1e52).toLocaleString('fullwide', { useGrouping: false });
    try {
      await baseToken.approve(stakedTokenAddress, amount);
      toast.success('Success!', {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      console.dir(err);
      toast.error('Faild!', {
        theme: "colored",
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const stakeHandle = async () => {
    let amount = (stakeAmount).toLocaleString('fullwide', { useGrouping: false });
    try {
      await stakedToken.stake(String(convertEthToWei(amount)));
      toast.success('Success!', {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      console.dir(err);
      toast.error('Faild!', {
        theme: "colored",
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const unstakeHandle = async () => {
    let amount = (unstakeAmount).toLocaleString('fullwide', { useGrouping: false });
    try {
      await stakedToken.unstake(String(convertEthToWei(amount)));
      toast.success('Success!', {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      console.dir(err);
      toast.error('Faild!', {
        theme: "colored",
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const setStakeAmountMax = () => {
    setStakeAmount(userGFIBalance);
    toast.warning('Max Stake - Please be sure you want to do that before confirming!', {
      position: "top-right",
      theme: "colored",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const setUnstakeAmountMax = () => {
    setUnstakeAmount(myStakedBalance);
    toast.warning('Max Unstake - Please be sure you want to do that before confirming!', {
      position: "top-right",
      theme: "colored",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  return (
    <Box className="stake-container pt-40 pb-20">
      <FadeInDiv className="stake-section pb-40 border-bottom-hr">
        <Container>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <Box className="card1   supply-card">
                <label className="card-title text-left">
                  My xTokens{" "}
                  <small>
                    <em>of Supply</em>
                  </small>
                </label>
                <img src={logoURL} alt="logo" />
                <span className="stakerAndHolder">{totalHolders ? totalHolders : "0"} SOFI Stakers, {totalStakers ? totalStakers : "0"} SOFI Holders.</span>
                <Stack
                  className="sub-card-group border-bottom-hr pb-20"
                  direction="row"
                  justifyContent="space-around"
                >
                  <Box className="sub-card">
                    <img alt="icon" src={icon1URL} />
                    {
                      userGFIBalance === -1 ? (
                        <img className="load" alt="icon" src={loaderURL} />
                      ) : (
                        <span>{userGFIBalance ? Number(userGFIBalance).toFixed(3) : "0.00"} SOFI</span>
                      )
                    }
                    <small>My SOFI</small>
                  </Box>
                  <Box className="sub-card">
                    <img alt="icon" src={icon2URL} />
                    {
                      myStakedBalance === -1 ? (
                        <img className="load" alt="icon" src={loaderURL} />
                      ) : (
                        <span>{myStakedBalance ? Number(myStakedBalance).toFixed(3) : "0.00"} xSOFI</span>
                      )
                    }
                    <small>My xSOFI</small>
                  </Box>
                  <Box className="sub-card">
                    <img alt="icon" src={icon3URL} />
                    {
                      myStakedBalanceUSD === -1 ? (
                        <img className="load" alt="icon" src={loaderURL} />
                      ) : (
                        <span>{myStakedBalanceUSD ? Number(myStakedBalanceUSD).toFixed(3) : "0.00"} USD</span>
                      )
                    }
                    <small>USD Value</small>
                  </Box>
                </Stack>
                <Stack
                  className="sub-card-group pb-20 pt-20"
                  direction="row"
                  justifyContent="space-around"
                >
                  <Box className="sub-card">
                    <img alt="icon" src={icon4URL} />
                    {
                      baseToStakedAmount === -1 ? (
                        <img className="load" alt="icon" src={loaderURL} />
                      ) : (
                        <span>{baseToStakedAmount ? Number(baseToStakedAmount).toFixed(3) : "0.000"}</span>
                      )
                    }
                    <small>Base Ratio</small>
                  </Box>
                  <Box className="sub-card">
                    <img alt="icon" src={icon5URL} />
                    {
                      stakedToBaseAmount === -1 ? (
                        <img className="load" alt="icon" src={loaderURL} />
                      ) : (
                        <span>{stakedToBaseAmount ? Number(stakedToBaseAmount).toFixed(3) : "0.000"}</span>
                      )
                    }
                    <small>Stake Ratio</small>
                  </Box>
                  <Box className="sub-card">
                    <img alt="icon" src={icon6URL} />
                    {
                      xGFIUserPercent === -1 ? (
                        <img className="load" alt="icon" src={loaderURL} />
                      ) : (
                        <span>{xGFIUserPercent}</span>
                      )
                    }
                    <small>Percentage</small>
                  </Box>
                </Stack>

                <small className="mb-20">
                  <span className="text-success">
                    <font color="#4EDD6B">
                      <span id="myTotalDeposited">{userTotalDeposited ? Number(userTotalDeposited).toFixed(2) : "0.00"}</span> Deposited
                    </font>
                  </span>
                  &nbsp; /&nbsp;
                  <span className="text-danger">
                    <font color="#FD392F">
                      <span id="myTotalWithdrawn">{userTotalWithdrawn ? Number(userTotalWithdrawn).toFixed(2) : "0.00"}</span> Withdrawn
                    </font>
                  </span>
                </small>

                <Stack className="btn-group" direction="row">
                  <Link to="">
                    <button className="btn" onClick={() => setIsShowTransferModal(true)}>
                      <span>
                        <ArrowForwardIcon size="small" />
                        <span>Transfer</span>
                      </span>
                    </button>
                  </Link>
                  <Link to="">
                    <button className="btn" onClick={makeItRainHandle}>
                      <span>
                        <img src={tintURL} alt="tint" />
                        <span>Make it Rain</span>
                      </span>
                    </button>
                  </Link>
                </Stack>
              </Box>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Box className="card1   stake-card">
                <strong className="card-title text-left">$ Stake</strong>
                <br />
                <br />
                <label className="card-title text-left">
                  <b>
                    <span className="baseTokenSymbol"></span> to Stake:{" "}
                    <small>
                      <em>
                        (You have&nbsp;
                        <span className="color2">
                          <span className="myBalance">{Number(userGFIBalance).toFixed(3)}&nbsp;SOFI</span>
                        </span>
                        )
                      </em>
                    </small>
                  </b>
                </label>
                <input
                  id="stake-tokens-amount"
                  className="form-control "
                  type="number"
                  placeholder="Tokens to Stake..."
                  value={stakeAmount ? stakeAmount : 0}
                  onChange={(e) => setStakeAmount(e.target.value)}
                />
                <small>Enter a value to get an estimate of xSOFI</small>

                <div className="border-bottom-hr"></div>

                <Stack className="btn-group" direction="row">
                  {!approvalState ? (
                    <Link to="">
                      <button className="btn" onClick={ApproveHandle}>
                        <span>
                          <AddIcon size="small" />
                          <span>Approve</span>
                        </span>
                      </button>
                    </Link>
                  ) : (
                    <Link to="">
                      <button className="btn" onClick={stakeHandle}>
                        <span>
                          <AddIcon size="small" />
                          <span>Stake SOFI</span>
                        </span>
                      </button>
                    </Link>
                  )}
                  <Link to="">
                    <button className="btn" onClick={setStakeAmountMax}>
                      <span>
                        <AddIcon size="small" />
                        <span>MAX</span>
                      </span>
                    </button>
                  </Link>
                </Stack>
              </Box>
              <Box className="card1   mt-20 unstake-card">
                <strong className="card-title text-left">$ Unstake</strong>
                <br />
                <br />
                <label className="card-title text-left">
                  <b>
                    <span className="baseTokenSymbol"></span> to Unstake:{" "}
                    <small>
                      <em>
                        (You have&nbsp;
                        <span className="color2">
                          <span className="myBalance">{myStakedBalance ? (Number(myStakedBalance).toFixed(3)) : "0.000"}&nbsp;xSOFI</span>
                        </span>
                        )
                      </em>
                    </small>
                  </b>
                </label>
                <input
                  id="stake-tokens-amount"
                  className="form-control "
                  type="number"
                  placeholder="Tokens to Stake..."
                  value={unstakeAmount ? unstakeAmount : 0}
                  onChange={(e) => setUnstakeAmount(e.target.value)}
                />
                <small>Enter a value to get an estimate of SOFI</small>

                <div className="border-bottom-hr"></div>

                <Stack className="btn-group" direction="row">
                  <Link to="">
                    <button className="btn" onClick={unstakeHandle}>
                      <span>
                        <RemoveIcon size="small" />
                        <span>Unstake</span>
                      </span>
                    </button>
                  </Link>
                  <Link to="">
                    <button className="btn" onClick={setUnstakeAmountMax}>
                      <span>
                        <RemoveIcon size="small" />
                        <span>MAX</span>
                      </span>
                    </button>
                  </Link>
                </Stack>
              </Box>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="card1   statistic">
                <strong className="card-title text-left">
                  Token / Staking Statistics
                </strong>
                <Stack direction="row" justifyContent="space-around">
                  <Box className="statistic-card">
                    <img src={refreshURL} alt="refresh" />
                    {
                      totalDeposited === -1 ? (
                        <img className="load" alt="icon" src={loaderURL} />
                      ) : (
                        <span>{totalDeposited ? numberWithCommas(Number(totalDeposited).toFixed(2)) : "0"} SOFI</span>
                      )
                    }
                    <small>Total In</small>
                  </Box>
                  <Box className="statistic-card">
                    <img alt="icon" src={icon2URL} />
                    {
                      totalWithdrawn === -1 ? (
                        <img className="load" alt="icon" src={loaderURL} />
                      ) : (
                        <span>{totalWithdrawn ? numberWithCommas(Number(totalWithdrawn).toFixed(2)) : "0"} xSOFI</span>
                      )
                    }
                    <small>Total Out</small>
                  </Box>
                  <Box className="statistic-card">
                    <img alt="icon" src={icon2URL} />
                    {
                      feesplitterBalance === -1 ? (
                        <img className="load" alt="icon" src={loaderURL} />
                      ) : (
                        <span>{feesplitterBalance ? numberWithCommas(Number(feesplitterBalance).toFixed(2)) : ""} SOFI</span>
                      )
                    }
                    <small>Rewards</small>
                  </Box>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </FadeInDiv>
      {
        netWrong && (
          <FadeInUpDiv className="second-section pt-40 pb-40 border-bottom-hr">
            <Container>
              <WrongNetwork />
            </Container>
          </FadeInUpDiv>
        )
      }
      <FadeInDiv className="third-section pt-40">
        <Container>
          <ContentFooter />
        </Container>
      </FadeInDiv>
      <TransferModal open={isShowTransferModal} openToggleHandle={setIsShowTransferModal} />
    </Box>
  );
}
