import { Navigate, useRoutes } from 'react-router-dom';
import DefaultLayout from "../layout/defaultLayout";

import Home from '../pages/home';
import About from '../pages/about';
import Armory from '../pages/armory';
import Claim from '../pages/claim';
import Dashboard from '../pages/dashboard';
import Liquidity from '../pages/liquidity';
import Profile from '../pages/profile';
import Reserve from '../pages/reserve';
import Rpc from '../pages/rpc';
import Setting from '../pages/setting';
import Stake from '../pages/stake';
import Staking from '../pages/staking';
import Stats from '../pages/stats';
import Swap from '../pages/swap';
import Vault from '../pages/vault';
import Vaults from '../pages/vaults';

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <DefaultLayout />, 
      children: [
        { path: '/', element: <Navigate to='/home' replace /> },
        { path: '/home', element: <Home /> }, 
        { path: '/about', element: <About /> }, 
        { path: '/claim', element: <Claim /> }, 
        { path: '/dashboard', element: <Dashboard /> }, 
        { path: '/liquidity', element: <Liquidity /> }, 
        { path: '/profile', element: <Claim /> }, 
        { path: '/setting', element: <Setting /> }, 
        // { path: '/stake/:token', element: <Stake /> }, 
        // { path: '/staking', element: <Staking /> }, 
        // { path: '/stats', element: <Stats /> }, 
        { path: '/vault/:id', element: <Vault /> }, 
        { path: '/vaults', element: <Vaults /> }, 
        { path: '/rpc', element: <Rpc /> }, 
        // { path: '/reserve', element: <Reserve /> }, 
        // { path: '/swap', element: <Swap /> }, 
        // { path: '/armory', element: <Armory /> }, 
      ]
    },
  ])
}