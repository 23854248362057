import { Box, Grid } from "@mui/material";

import telegram from "../../assets/img/icon/telegram.png";
import TwitterIcon from "@mui/icons-material/Twitter";
import gitbook from "../../assets/img/icon/gitbook.png";
import GitHubIcon from '@mui/icons-material/GitHub';

import "./contentFooter.scss";

export default function ContentFooter() {
  return (
    <Box className="card1 contentFooter">
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} sm={6} xs={12} className="left">
          <h5>SONIC FINANCE</h5>
          <p>
            <font color="#FE4C4E">NOTICE: SOFI token will be launched on August</font>
          </p>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={6} className="right">
          <h5>SOCIAL</h5>
          <a href="https://t.me/sonicfinance" target="_blank">
            <img src={telegram} className="tele" alt="telegram" />
            Telegram
          </a>
          <a href="https://twitter.com/sonicfinance_" target="_blank">
            <TwitterIcon />
            Twitter
          </a>
        </Grid>
        {/* <Grid item lg={3} md={3} sm={3} xs={6} className="right">
          <h5>RESOURCES</h5>
          <a href="https://github.com/" target="_blank">
            <GitHubIcon />
            Github
          </a>
        </Grid> */}
      </Grid>
    </Box>
  );
}
