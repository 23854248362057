import { Box, Grid, Stack } from "@mui/material";
import { Link } from "react-router-dom";

import croURL from "../../assets/img/cro.png";
import bnbURL from "../../assets/img/bnb.png";
import logoURL from "../../assets/img/logo.gif";

import { Container } from "@mui/system";
import ContentFooter from "../../components/contentFooter";

import "./rpc.scss";

import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";

const fadeInAnimation = keyframes`${fadeIn}`;

const FadeInDiv = styled.div`
  animation: 1s ${fadeInAnimation};
`;

export default function Rpc() {
  return (
    <Box className="rpc-container pt-40 pb-20">
      <FadeInDiv className="pb-40 border-bottom-hr">
        <Container>
          <Grid container spacing={2} columns={12}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Stack
                direction="column"
                alignItems="center"
                className="rpc-head-section"
              >
                <img src={logoURL} alt="logo" />
                <h3 className="subtitle">SOFI's Future RPCs</h3>
                <span>
                  Add the chains supported by SONIC FINANCE, quickly and easily!
                </span>
              </Stack>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box className="card1   chain-card">
                <Stack direction="column" className="content-part">
                  <Stack direction="row">
                    <img src={croURL} alt="cro" />
                    <Stack direction="column">
                      <strong>Cronos Mainnet (25)</strong>
                      <small>Supports all main features of the protocol</small>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  className="btns-part"
                >
                  <a>
                    <button className="btn btn-success">Add Chain</button>
                  </a>
                  <a
                    href="https://cronos.org/docs/resources/chain-integration.html"
                    target="_blank"
                  >
                    <button className="btn">Learn More</button>
                  </a>
                </Stack>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box className="card1   chain-card">
                <Stack direction="column" className="content-part">
                  <Stack direction="row">
                    <img src={bnbURL} alt="bnb" />
                    <Stack direction="column">
                      <strong>Binance Smart Chain (56)</strong>
                      <small>Provides support for legacy vaults</small>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  className="btns-part"
                >
                  <a>
                    <button className="btn btn-success">Add Chain</button>
                  </a>
                  <a
                    href="https://docs.bnbchain.org/docs/learn/intro"
                    target="_blank"
                  >
                    <button className="btn">Learn More</button>
                  </a>
                </Stack>
              </Box>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Stack direction="column" spacing={2} className="btn-group">
                <Link to="">
                  <button className="btn btn-info">
                    <span>Add ETH Mainnet</span>
                  </button>
                </Link>
                <Link to="">
                  <button className="btn btn-warning">
                    <span>Add BNB Mainnet</span>
                  </button>
                </Link>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </FadeInDiv>
      <FadeInDiv className="third-section pt-40">
        <Container>
          <ContentFooter />
        </Container>
      </FadeInDiv>
    </Box>
  );
}
