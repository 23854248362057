import { Box, Grid, Stack } from "@mui/material";
import { Link } from "react-router-dom";

import logoURL from "../../assets/img/logo.gif";

import { Container } from "@mui/system";
import ContentFooter from "../../components/contentFooter";
import WrongNetwork from "../../components/wrongNetwork";

import "./liquidity.scss";

import styled, { keyframes } from "styled-components";
import { fadeInUp } from "react-animations";

const fadeInUpAnimation = keyframes`${fadeInUp}`;

const FadeInUpDiv = styled.div`
  animation: 1s ${fadeInUpAnimation};
`;

export default function Liquidity() {
  return (
    <Box className="liquidity-container pt-40 pb-20">
      <Box className="liquidity-section pb-40 border-bottom-hr">
        <Container>
          <Grid container spacing={8}>
            <Grid item md={6} sm={12} xs={12}>
              <Box className="card1   SOFI-wrap">
                <strong className="card-title text-left">
                  <span className="text-white" id="priceData">
                    <span id="bnbValue">0.00</span> BNB per SOFI | $
                    <span id="usdValue">0.00</span> USD per SOFI
                  </span>
                </strong>
                <img src={logoURL} alt="logo" />
                <Stack
                  className="price-wrap"
                  direction="row"
                  justifyContent={"space-around"}
                >
                  <Stack direction="column" alignItems="center">
                    <h3 className="subtitle mt-20 mb-20">0.00</h3>
                    <small>USDC</small>
                  </Stack>
                  <Stack direction="column" alignItems="center">
                    <h3 className="subtitle mt-20 mb-20">0.00</h3>
                    <small>SOFI</small>
                  </Stack>
                </Stack>
                <Stack
                  className="tmp-wrap"
                  direction={{ lg: "row", md: "row", sm: "row", xs: "column" }}
                  justifyContent={"space-around"}
                  spacing={1}
                >
                  <Stack direction="column">
                    <label>WETH to Spend:</label>
                    <input className="form-control" type="text" />
                    <Link to="">
                      <button className="btn btn-success">But SOFI</button>
                    </Link>
                  </Stack>
                  <Stack direction="column">
                    <label>SOFI to Sell:</label>
                    <input className="form-control" type="text" />
                    <Link to="">
                      <button className="btn btn-danger">Sell SOFI</button>
                    </Link>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Box className="card1   more-control">
                <strong className="card-title text-left">More Controls</strong>

                <Box className="form-group first">
                  <Link to="">
                    <button className="btn">Remove, Buy & Tax</button>
                  </Link>
                  <Link to="">
                    <button className="btn">Buy & Tax</button>
                  </Link>
                  <Link to="">
                    <button className="btn">Balance Price</button>
                  </Link>
                </Box>
                <Box className="form-group second">
                  <Link to="">
                    <button className="btn">Wrap / Unwrap</button>
                  </Link>
                  <Link to="">
                    <button className="btn">Zap...</button>
                  </Link>
                </Box>
              </Box>
              <Box className="card1   mt-20 vault-control">
                <strong className="card-title text-left">Vault Controls</strong>

                <Stack
                  direction="row"
                  className="form-group"
                  justifyContent="space-between"
                >
                  <Link to="">
                    <button className="btn btn-success">Sweep Floor</button>
                  </Link>
                  <Link to="">
                    <button className="btn btn-success">Set Manager</button>
                  </Link>
                </Stack>
              </Box>
              <Box className="card1   mt-20 elite">
                <strong className="card-title text-left">Elite (eGFI2)</strong>

                <Stack
                  direction="row"
                  className="form-group"
                  justifyContent="space-between"
                >
                  <Link to="">
                    <button className="btn btn-success">Sweep Floor</button>
                  </Link>
                  <Link to="">
                    <button className="btn btn-success">Set Sweeper</button>
                  </Link>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <FadeInUpDiv className="second-section pt-40 pb-40 border-bottom-hr">
        <Container>
          <WrongNetwork />
        </Container>
      </FadeInUpDiv>
      <Box className="third-section pt-40">
        <Container>
          <ContentFooter />
        </Container>
      </Box>
    </Box>
  );
}
