import { useState } from "react";
import { Box, Grid, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import { useDispatch } from "react-redux";
import { ethers } from "ethers";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import { Container } from "@mui/system";
import ContentFooter from "../../components/contentFooter";
import WrongNetwork from "../../components/wrongNetwork";

import "./setting.scss";

import styled, { keyframes } from "styled-components";
import { fadeIn, fadeInUp } from "react-animations";
import { Font_toggle } from "../../redux/actions";
import croURL from "../../assets/img/cro.png";
import logoURL from "../../assets/img/logo.gif";
import bnbURL from "../../assets/img/bnb.png";
import { Common } from "../../redux/common";
import { PrinceFooter_toggle } from "../../redux/actions";

const fadeInAnimation = keyframes`${fadeIn}`;
const fadeInUpAnimation = keyframes`${fadeInUp}`;

const FadeInDiv = styled.div`
  animation: 1s ${fadeInAnimation};
`;
const FadeInUpDiv = styled.div`
  animation: 1s ${fadeInUpAnimation};
`;

const fileTypes = ["JPEG", "PNG", "GIF", "JPG"];

export default function Setting() {
  const { font, netWrong, isShowFooter } = Common();
  const dispatch = useDispatch();

  const [file, setFile] = useState(null);
  const [ticker, setTicker] = useState(isShowFooter);
  const handleBgImageChange = (file) => {
    setFile(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");
      localStorage.setItem("wallpaper", base64String);
      document.querySelector(
        ".content-wrap"
      ).style.backgroundImage = `url(data:image/png;base64,${base64String})`;
    };
    reader.readAsDataURL(file);
    document.querySelector(".uploader>div>span").innerText = file
      ? "SUCCESS"
      : "";
    document.querySelector(".uploader>div>span>span").innerHTML = "";
  };

  const [nextFont, setNextFont] = useState(font ? font : "sofi");

  const changeFontHandle = (event) => {
    setNextFont(event.target.value);
    localStorage.setItem("Font", event.target.value);
    dispatch(Font_toggle(event.target.value));
  };

  const addNetwork = (network) => {
    if (network == "binance") {
      window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0x38",
            rpcUrls: ["https://bsc-dataseed.binance.org/"],
            chainName: "Binance Mainnet",
            nativeCurrency: {
              name: "Binance",
              symbol: "BNB",
              decimals: 18,
            },
            blockExplorerUrls: ["https://bscscan.com/"],
          },
        ],
      });
    } else {
      window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0x19",
            rpcUrls: ["https://rpc.vvs.finance/"],
            chainName: "CRO Mainnet",
            nativeCurrency: {
              name: "MATIC",
              symbol: "CRO",
              decimals: 18,
            },
            blockExplorerUrls: ["https://cronoscan.com/"],
          },
        ],
      });
    }
  };

  const togglePriceSlider = (select) => {
    setTicker(select);
    dispatch(PrinceFooter_toggle({ isShowFooter: select }));
  };

  return (
    <Box className="setting-container pt-40 pb-20">
      <FadeInDiv className="pb-40 border-bottom-hr">
        <Container>
          <Grid container spacing={2} columns={12}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Stack
                direction="column"
                alignItems="center"
                className="rpc-head-section"
              >
                <img src={logoURL} alt="logo" />
                <h3 className="subtitle">SOFI's Future RPCs</h3>
                <span>
                  Add the chains supported by SONIC FINANCE, quickly and <br />
                  easily! These chains are in maintenance right now!
                </span>
              </Stack>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box className="card1   chain-card">
                <Stack direction="column" className="content-part">
                  <Stack direction="row">
                    <img src={croURL} alt="cro" />
                    <Stack direction="column">
                      <strong>Cronos Mainnet (25)</strong>
                      <small>Supports all main features of the protocol</small>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  className="btns-part"
                >
                  <a>
                    <button
                      onClick={() => addNetwork("cronos")}
                      className="btn btn-success"
                    >
                      Add Chain
                    </button>
                  </a>
                  <a
                    href="https://cronos.org/docs/resources/chain-integration.html"
                    target="_blank"
                  >
                    <button className="btn">Learn More</button>
                  </a>
                </Stack>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box className="card1   chain-card">
                <Stack direction="column" className="content-part">
                  <Stack direction="row">
                    <img src={bnbURL} alt="bnb" />
                    <Stack direction="column">
                      <strong>Binance Smart Chain (56)</strong>
                      <small>Provides support for legacy vaults</small>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  className="btns-part"
                >
                  <a>
                    <button
                      onClick={() => addNetwork("binance")}
                      className="btn btn-success"
                    >
                      Add Chain
                    </button>
                  </a>
                  <a
                    href="https://docs.bnbchain.org/docs/learn/intro"
                    target="_blank"
                  >
                    <button className="btn">Learn More</button>
                  </a>
                </Stack>
              </Box>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Stack
                direction="row"
                spacing={2}
                className="btn-group net-group"
              >
                <Link to="">
                  <button
                    onClick={() => addNetwork("cronos")}
                    className="btn btn-info"
                  >
                    <span>Add CRO Mainnet</span>
                  </button>
                </Link>
                <Link to="">
                  <button
                    onClick={() => addNetwork("binance")}
                    className="btn btn-warning"
                  >
                    <span>Add BNB Mainnet</span>
                  </button>
                </Link>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </FadeInDiv>
      <Box className="setting-section pb-40 border-bottom-hr">
        <Container>
          <Grid container spacing={8}>
            <Grid item md={3} sm={12} xs={12}></Grid>
            <Grid item md={6} sm={12} xs={12}>
              <FadeInDiv className="card1   setting">
                <strong className="card-title text-left">Site Settings</strong>
                <Box className="tmp-wrap">
                  <Link to="">
                    <button
                      className={
                        "btn btn-success" + (ticker ? " selected" : "")
                      }
                      onClick={() => togglePriceSlider(true)}
                    >
                      <span>
                        <span>✅ Enable Price Ticker</span>
                      </span>
                    </button>
                  </Link>
                  <Link to="">
                    <button
                      className={
                        "btn btn-danger" + (!ticker ? " selected" : "")
                      }
                      onClick={() => togglePriceSlider(false)}
                    >
                      <span>
                        <span>❌ Disable Price Ticker</span>
                      </span>
                    </button>
                  </Link>
                </Box>
                <Box className="page-set">
                  <span>Wallpaper: </span>
                  <FileUploader
                    classes="uploader"
                    multiple={false}
                    name="file"
                    label={" "}
                    types={fileTypes}
                    handleChange={handleBgImageChange}
                  />
                </Box>
                <FormControl className="page-set">
                  <FormLabel id="font-type-label">Font:</FormLabel>
                  <RadioGroup
                    aria-labelledby="font-type-label"
                    name="radio-buttons-group"
                    value={nextFont}
                    onChange={changeFontHandle}
                  >
                    <FormControlLabel
                      value="sofi"
                      control={<Radio />}
                      label="Sonic Font"
                    />
                    <FormControlLabel
                      value="pancake"
                      control={<Radio />}
                      label="Pancake Font"
                    />
                    <FormControlLabel
                      value="gagalin"
                      control={<Radio />}
                      label="Gagalin Font"
                    />
                  </RadioGroup>
                </FormControl>
              </FadeInDiv>
            </Grid>
            <Grid item md={3} sm={12} xs={12}></Grid>
            <Grid item md={3} sm={12} xs={12}></Grid>
            <Grid item md={6} sm={12} xs={12}>
              <FadeInDiv className="card1   upline">
                <strong className="card-title text-left">Default Upline</strong>
                <h3 className="subtitle text-left mt-20">0x...</h3>
                <Stack
                  direction="column"
                  className="form-group border-bottom-hr mt-20 mb-20"
                >
                  <label>Upline Address</label>
                  <input className="form-control" />
                  <small>Enter a valid Address</small>

                  <Link to="">
                    <button className="btn btn-success">
                      <span>Set Default Upline</span>
                    </button>
                  </Link>
                </Stack>

                <strong className="card-title text-left">
                  How does this work?
                </strong>
                <small>
                  Your upline will be used in all team-related aspects of SONIC
                  FINANCE and its future offerings. By setting an upline, your
                  address becomes part of one giant "team" - however, each
                  application or future offering may allow for micro-teams
                  within the larger structure.
                </small>
              </FadeInDiv>
            </Grid>
            <Grid item md={3} sm={12} xs={12}></Grid>
          </Grid>
        </Container>
      </Box>
      {netWrong && (
        <FadeInUpDiv className="second-section pt-40 pb-40 border-bottom-hr">
          <Container>
            <WrongNetwork />
          </Container>
        </FadeInUpDiv>
      )}
      <FadeInDiv className="third-section pt-40">
        <Container>
          <ContentFooter />
        </Container>
      </FadeInDiv>
    </Box>
  );
}
