import { Box, Stack, Grid } from "@mui/material";

import card1 from "../../assets/img/cards/card1.png";
import card2 from "../../assets/img/cards/card2.png";
import card3 from "../../assets/img/cards/card3.png";
import card4 from "../../assets/img/cards/card4.png";

import { Container } from "@mui/system";
import ContentFooter from "../../components/contentFooter";
import WrongNetwork from "../../components/wrongNetwork";

import styled, { keyframes } from "styled-components";
import { fadeIn, fadeInUp } from "react-animations";

const fadeInAnimation = keyframes`${fadeIn}`;
const fadeInUpAnimation = keyframes`${fadeInUp}`;

const FadeInDiv = styled.div`
  animation: 1s ${fadeInAnimation};
`;
const FadeInUpDiv = styled.div`
  animation: 1s ${fadeInUpAnimation};
`;

export default function About() {
  return (
    <Box className="about-container pt-20 pb-20">
      <FadeInDiv className="about-section pb-40 border-bottom-hr">
        <Container>
          <Grid container spacing={4} columns={12}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Stack>
                  <img src={card1} alt={"About Explain Image"} />
                  <p className="subtitle">🚀 Deposit SONIC</p>
                  <small className="subdescription">Upon depositing SONIC, you will receive SOFI. These SOFI are "shares" of the Hourglass - while you hold them in your wallet, they represent your proportionate share of all future Deposit and Withdrawal fees of the contract. Hold for the long-term and gain dividends as others buy and sell SOFI!</small>
              </Stack>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Stack>
                  <img src={card2} alt={"About Explain Image"} />
                  <p className="subtitle">♻ Compound Earnings</p>
                  <small className="subdescription">When you hold SOFI, you earn SONIC dividends - Quickly increase your portion of the Hourglass supply by compounding them! This saves an extra transaction of having to withdraw then re-deposit!</small>
              </Stack>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Stack>
                  <img src={card3} alt={"About Explain Image"} />
                  <p className="subtitle">💸 Withdraw SONIC</p>
                  <small className="subdescription">If you so desire, you can sell your SOFI and collect the underlying SONIC from the contract. Just enter an amount, press "Withdraw" and check your dividends total!</small>
              </Stack>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Stack>
                  <img src={card4} alt={"About Explain Image"} />
                  <p className="subtitle">🌾 Harvest SONIC</p>
                  <small className="subdescription">Your dividends and earnings will grow as others continue to use the contract - to get your SONIC back into your wallet, you will need to harvest it first!</small>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </FadeInDiv>
      <FadeInUpDiv className="second-section pb-40 pt-40 border-bottom-hr">
        <Container>
          <WrongNetwork />
        </Container>
      </FadeInUpDiv>
      <FadeInUpDiv className="third-section pt-40">
        <Container>
          <ContentFooter />
        </Container>
      </FadeInUpDiv>
    </Box>
  );
}
