export const SLIDER_BAR_TOGGLE = "SLIDER_BAR_TOGGLE";
export const MODE_TOGGLE = "MODE_TOGGLE";
export const FONT_TOGGLE = "FONT_TOGGLE";
export const WALLET_CONNECT = "WALLET_CONNECT";
export const WALLET_DISCONNECT = "WALLET_DISCONNECT";
export const VIDEO_TOGGLE = "VIDEO_TOGGLE";
export const NET_WRONG = "NET_WRONG";
export const GET_PRICE = "GET_PRICE";
export const GET_GLOBAL_CONTRACT = "GET_GLOBAL_CONTRACT";
export const CONTRACTS_SET_INFORMATION = "CONTRACTS_SET_INFORMATION";
export const PRINCE_FOOTER_TOGGLE = "PRINCE_FOOTER_TOGGLE";
